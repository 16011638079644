import { Box, ButtonBase, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import { useIntl } from 'react-intl'
import ChartCardMenu from '../ResourceCollection/Collections/DataHub/DataInsights/Charts/ChartCardMenu'

interface AssessmentGraphCardProps {
  children: React.ReactNode
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
  periods?: string[]
  assessmentTemplateName: string
}

const AssessmentGraphCard: React.FC<AssessmentGraphCardProps> = ({
  children,
  eChartsRef,
  onClickTitle,
  chartSelector,
  periods,
  assessmentTemplateName,
}) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="start">
          <Box>
            <Box component={ButtonBase} onClick={onClickTitle}>
              <Typography variant="subtitle1">{formatMessage({ id: 'assessments.results' })}</Typography>
            </Box>
            <Typography variant="body1" color="text.secondary">
              {periods?.join(', ')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="start" gap={1}>
          {chartSelector}
          <ChartCardMenu
            echartsRef={eChartsRef}
            title={formatMessage(
              { id: 'assessments.overviewGraph.chartImageExportFilename' },
              { assessmentName: assessmentTemplateName.replace('&', 'and'), period: periods?.join(' ') },
            )}
            useTitleAsIs
          />
        </Box>
      </Box>
      {children}
    </>
  )
}

export default AssessmentGraphCard
