import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import RiskScreeningHqFilters from '@app/src/pages/ResourceCollection/Filters/RiskScreeningHqFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Provider, RiskTableView } from '@app/src/types/organizations'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { TuneOutlined } from '@mui/icons-material'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import RiskScreeningHqHeader from './RiskScreeningHqHeader'
import RiskScreeningHqRow from './RiskScreeningHqRow'

interface RiskScreeningHqSceneProps {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  subtitle?: React.ReactNode
}

const allowedFilters: string[] = [
  'id',
  'name',
  'country.id',
  'createdAt',
  'ownerUserId',
  'websiteAddress',
  'vatNumber',
  'registrationNumber',
  'linkStatus',
  'categoryOptions.id',
  'activityStatus',
  'finalRiskRating',
  'priority',
  'providerApprovalStatus',
  'supplierUsage',
  'tier',
  'organization.id',
  'mappingNodes.actorTypeModel.id',
  'mappingNodes.tier',
  'riskStatus',
  'spends.periodName',
  'spends.spendClassification',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.intervalType',
  'assessments.section1Level',
  'assessments.section2Level',
  'assessments.section3Level',
  'assessments.section4Level',
  'assessments.section5Level',
  'assessments.assessmentTemplate.name',
  'assessments.assessmentTemplate.AssessmentType',
]

const RiskScreeningHqScene: React.FC<RiskScreeningHqSceneProps> = ({ tabs, actionButtons, subtitle }): JSX.Element => {
  const filters = useGetApiQueryFilters(allowedFilters)
  const [pageNumber, itemsPerPage, setPage, setPageSize] = usePagination()
  const { sorting, toggleSorting } = useSort()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { activeRiskSettings, isLoadingRiskSettings } = useRiskSettings()

  const [selectedProviders, setSelectedProviders] = useState<Array<number>>([])
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)

  const payload = {
    filter: filters,
    pagination: {
      pageNumber,
      itemsPerPage,
    },
    sort: sorting,
    include: [],
  }

  const rawExportPayload = {
    filter: [],
    pagination: {
      pageNumber,
      itemsPerPage,
    },
    sort: sorting,
    include: [],
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<RiskTableView>({
    key: FetchKey.RiskScreening,
    endpoint: endpoints.riskScreening,
    payload,
    options: {
      enabled: Boolean(activeRiskSettings.length),
    },
  })

  const buttons: ActionButton[] = [
    ...(actionButtons ?? []),
    {
      label: formatMessage({ id: 'resourceCollections.general.export' }),
      variant: 'outlined',
      onClick: () =>
        openDrawer(
          <DrawerViewExport
            resourceType={ResourceTypes.RiskScreening}
            count={count}
            userFilter={filters}
            rawExportPayload={rawExportPayload}
          />,
        ),
      disabled: isLoading || count === 0,
    },
  ]

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviders(prev => [...prev, provider.id])
    } else {
      setSelectedProviders(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.riskScreening' })}
      actionButtons={buttons}
      tabs={tabs}
      subtitle={subtitle}
      filter={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {isLoading ? (
              <Skeleton variant="rounded" width={100} />
            ) : (
              <Typography variant="body2" color="textSecondary">
                {formatMessage({ id: 'riskScreening.companiesCount' }, { count })}
              </Typography>
            )}
            {Boolean(selectedProviders?.length) && (
              <Button
                variant="outlined"
                onClick={openUpdateDrawer}
                disabled={isLoading || isFetching}
                sx={{ ml: 2 }}
                size="small"
              >
                {formatMessage({ id: 'riskScreening.categorize' }, { count: selectedProviders.length })}
              </Button>
            )}
          </Box>
          <RiskScreeningHqFilters allowedFilters={allowedFilters} />
        </Box>
      }
    >
      <Table<RiskTableView>
        RowComponent={({ row }): JSX.Element => (
          <RiskScreeningHqRow
            row={row}
            enabledRiskTypes={activeRiskSettings}
            onCheckboxChange={handleCheckboxChange}
            selectedProviders={selectedProviders}
            activeFilters={filters}
          />
        )}
        HeaderComponent={() => (
          <RiskScreeningHqHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            enabledRiskTypes={activeRiskSettings}
            selectedProviders={selectedProviders}
            setSelectedProviders={setSelectedProviders}
            providersInPage={items.map(i => i.parentObject)}
          />
        )}
        data={items}
        page={pageNumber}
        pageSize={itemsPerPage}
        setPage={setPage}
        setPageSize={setPageSize}
        isLoading={isLoading || isFetching || isLoadingRiskSettings}
        count={count}
        isError={isError}
        emptyState={
          !activeRiskSettings.length && (
            <EmptyState
              iconComponent={TuneOutlined}
              title={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.title' })}
              description={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.description' })}
            >
              <LinkButton
                variant="outlined"
                to={generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.RiskScreening })}
              >
                {formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.action' })}
              </LinkButton>
            </EmptyState>
          )
        }
      />
      <UpdateProvidersDrawer
        selectedProviders={items
          .filter(riskView => selectedProviders.includes(riskView.parentObject.id))
          .map(riskView => riskView.parentObject)
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedProviders}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </ResourceCollectionScene>
  )
}

export default RiskScreeningHqScene
