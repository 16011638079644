import { Permissions, usePermissions } from '@app/src/auth/permissions'
import TextField from '@app/src/components/Ui/TextField'
import { RequestCollectionPicker } from '@app/src/types/resourceExplorer'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useIntl } from 'react-intl'

export type PickerFormData = {
  items: { value: string }[]
} & Omit<RequestCollectionPicker, 'permittedOptions'>
interface PickerBasicFormFieldsProps {
  formMethods: UseFormMethods<PickerFormData>
}
const PickerBasicFormFields: React.FC<PickerBasicFormFieldsProps> = ({ formMethods }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const { register, errors, setValue, control } = formMethods

  const canCreateGlobalPicker = hasPermission(Permissions.WF_EMAIL_ACCESS)

  return (
    <>
      <TextField
        fullWidth
        label={formatMessage({ id: 'templateBuilder.nameOfThePicker' })}
        name="name"
        inputRef={register({
          required: formatMessage({ id: 'form.validation.required' }),
        })}
        required
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
        onClear={(): void => setValue('name', '')}
        inputProps={{
          maxLength: 100,
        }}
      />

      {canCreateGlobalPicker && (
        <FormControl variant="filled" component="fieldset" required>
          <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.pickerPerimeter' })}</FormLabel>
          <Controller
            name="isGlobal"
            control={control}
            defaultValue={false}
            render={({ onChange, value }): JSX.Element => (
              <RadioGroup onChange={onChange} value={value?.toString()}>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={formatMessage({ id: 'templateBuilder.organizationSpecific' })}
                />
                <FormControlLabel
                  value
                  control={<Radio />}
                  label={formatMessage({ id: 'templateBuilder.globalWorldfavor' })}
                />
              </RadioGroup>
            )}
          ></Controller>
        </FormControl>
      )}
    </>
  )
}

export default PickerBasicFormFields
