import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import useExportEchartsToPDF from '@app/src/components/ExportToPDF/useExportEchartsToPdf'
import FilterDisplayChip from '@app/src/components/FilterDisplayChip'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useAmplitudeTimeSpentTracking } from '@app/src/hooks/useAmplitudeTimeSpentTracking'
import DataHubInsights from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubInsights'
import { useDataInsightsModal } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalContext'
import DatahubDataInsightsFilters from '@app/src/pages/ResourceCollection/Filters/DatahubDataInsightsFilters'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { getCurrentTimestamp } from '@app/src/utils'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { AmplitudeTrackingEvents, spendClassificationOrder } from '@app/src/wf-constants'
import { BarChartOutlined, ListAltOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Chip, Grid, Stack, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { DataInsightsQuestionsQuery, StatisticsForQuestionPerGroup } from '../../DataHubScene'
import DataHubInsightsDetails from './Details/DataHubInsightsDetails'

const questionFilters = [
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.questionnaireTemplateId',
].map(q => q.toLowerCase())

type DataHubInsightsSceneProps = {
  tabs: ResourceCollectionSceneProps['tabs']
}

export enum InsightsSubTabs {
  INSIGHTS,
  SUMMARY,
  DETAILS,
}

const DataHubInsightsScene: React.FC<DataHubInsightsSceneProps> = ({ tabs }) => {
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const [activeTab, setActiveTab] = React.useState<InsightsSubTabs>(InsightsSubTabs.INSIGHTS)
  const appliedFiltersRef = useRef(null)
  const { groupBy: groupByFlag } = useFlags()
  const { groupBy, setGroupBy } = useGroupBySelector()

  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { generatePDF, isGeneratingPdf } = useExportEchartsToPDF()
  const { echartsRefs, clearEchartsRefOverIndex } = useDataInsightsModal()

  const anyQuestionFilterSet = useMemo(
    () => userFilters.some(filter => questionFilters.includes(filter.name.toLowerCase())),
    [userFilters],
  )

  const filteredPeriodNames = useMemo(() => {
    const periodNameFilterValue = userFilters.find(x => x.name === 'response.request.periodName')?.filters[0]
      ?.value as string[]

    const safePeriodFilterValue = Array.isArray(periodNameFilterValue) ? periodNameFilterValue : [periodNameFilterValue]
    return safePeriodFilterValue?.sort((a, b) => comparePeriods(a, b))
  }, [userFilters])

  const isDetailsTabActive = activeTab === InsightsSubTabs.DETAILS
  const isSummaryTabActive = activeTab === InsightsSubTabs.SUMMARY

  useAmplitudeTimeSpentTracking({
    eventName: AmplitudeTrackingEvents.Analyze.DataHub.Insights.LeftPage,
    timerStarted: anyQuestionFilterSet,
  })

  const { data: questions, isLoading } = useFetchPost<StatisticsForQuestionPerGroup[], DataInsightsQuestionsQuery>({
    payload: {
      filter: userFilters,
      groupBy: groupByFlag ? groupBy : AnswersGroupingType.PeriodName,
    },
    endpoint: endpoints.dataInsightsByQuestions,
    key: [FetchKey.ResponseItemDataInsights],
    options: { enabled: anyQuestionFilterSet },
  })

  useEffect(() => {
    if (groupBy === AnswersGroupingType.SpendClassification && questions) {
      questions.forEach(question => {
        question.resultForGroups = question.resultForGroups.sort((a, b) => {
          return spendClassificationOrder.indexOf(a.group) - spendClassificationOrder.indexOf(b.group)
        })
      })
    }
  }, [questions, groupBy])

  useEffect(() => {
    clearEchartsRefOverIndex(questions?.length ?? 0)
  }, [clearEchartsRefOverIndex, questions?.length])

  const exportChartsToPdf = async () => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.DataHub.Insights.PdfExport,
    })
    const timestamp = getCurrentTimestamp()
    const pdfFileName = `DataInsights_${timestamp}.pdf`

    const dataToExport =
      questions?.flatMap((question, index) => {
        const echartsRef = echartsRefs?.[index]?.current
        if (!echartsRef) return []
        return { title: question.title, echarts: echartsRef }
      }) ?? []
    await generatePDF(pdfFileName, dataToExport, appliedFiltersRef)
  }

  const disableExport =
    !anyQuestionFilterSet || !questions?.length || echartsRefs.every(r => !r?.current) || isDetailsTabActive

  return (
    <ResourceCollectionScene
      title={formatMessage({
        id: 'schemas.responseItem.responseItems',
      })}
      tabs={tabs}
      filter={
        <DatahubDataInsightsFilters
          allowedFilters={dataHubAllowedFilters}
          anyQuestionFilterSet={anyQuestionFilterSet}
          isDetailsTabActive={isDetailsTabActive}
        />
      }
      actionButtons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.exportToPdf' }),
          variant: 'outlined' as const,
          onClick: exportChartsToPdf,
          disabled: disableExport,
          loading: isGeneratingPdf,
        },
      ]}
      enableScroll
    >
      <Box display="flex" py={3} flexDirection="row-reverse">
        <Stack alignItems="center" direction="row">
          <ButtonGroup sx={{ mr: 4 }}>
            <Button
              startIcon={<BarChartOutlined />}
              onClick={() => {
                setActiveTab(InsightsSubTabs.INSIGHTS)
              }}
              variant={activeTab === InsightsSubTabs.INSIGHTS ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'resourceTypes.insight' })}
            </Button>
            <Button
              startIcon={<BarChartOutlined />}
              onClick={() => setActiveTab(InsightsSubTabs.SUMMARY)}
              variant={isSummaryTabActive ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'general.summary' })}
            </Button>
            <Button
              startIcon={<ListAltOutlined />}
              onClick={() => {
                setActiveTab(InsightsSubTabs.DETAILS)
                setGroupBy(AnswersGroupingType.PeriodName)
              }}
              variant={isDetailsTabActive ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'general.details' })}
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
      {isDetailsTabActive ? (
        <DataHubInsightsDetails />
      ) : (
        <DataHubInsights
          questions={questions}
          isLoading={isLoading}
          activeTab={activeTab}
          periodNames={filteredPeriodNames}
        />
      )}
      {Boolean(userFilters?.length) && (
        <Box ref={appliedFiltersRef}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Stack direction="row" spacing={1} my={2}>
                <Typography whiteSpace="nowrap" mt={0.5}>
                  Filters applied:
                </Typography>
                <Stack direction="row" ml={-1} flexWrap="wrap">
                  {groupByFlag && (
                    <Chip
                      label={`${formatMessage({ id: `dataHub.groupBy.name` })}: ${groupBy === AnswersGroupingType.PeriodName ? formatMessage({ id: `reporting.period` }) : formatMessage({ id: `dataHub.groupBy.spend` })}`}
                      size="small"
                      sx={theme => ({
                        margin: theme.spacing(0.5),
                        height: 'auto',
                        whiteSpace: 'wrap',
                        '& .MuiChip-label': {
                          whiteSpace: 'wrap',
                          wordBreak: 'break-word',
                        },
                      })}
                    />
                  )}
                  {userFilters.map(filter => (
                    <FilterDisplayChip
                      key={`${filter.name}-${filter.filters[0].operator}`}
                      filterName={filter.name}
                      filterValue={filter.filters[0].value}
                      operator={filter.filters[0].operator}
                    />
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </ResourceCollectionScene>
  )
}

export default DataHubInsightsScene
