import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { NumberMultiGroupChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/NumberMultiGroupChart'
import { OptionSingleGroupPieChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/OptionSingleGroupPieChart'
import { TextAndFileMultiGroupChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/TextAndFileMultiGroupChart'
import { QuestionTypes } from '@app/src/wf-constants'
import BarChartIcon from '@mui/icons-material/BarChart'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import { NumberMultiGroupLineChart } from './NumberMultiGroupLineChart'
import { OptionMultiGroupChart } from './OptionMultiGroupChart'

interface DataHubInsightsCardProps {
  statistics: StatisticsForQuestionPerGroup
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
}

const DataInsightsChart: React.FC<DataHubInsightsCardProps> = ({ statistics, eChartsRef, selectedChartType }) => {
  const { formatMessage } = useIntl()

  const height = '420px'

  const noData = statistics.resultForGroups.every(r => r.questionMonitoring.companiesHaveAnswered.number === 0)
  if (noData)
    return (
      <EmptyState
        sx={{ height: height }}
        title={formatMessage({ id: 'statistics.dataInsights.noDataForPeriod' })}
        iconComponent={BarChartIcon}
        variant={EmptyStateVariant.Small}
      />
    )

  switch (statistics.questionType) {
    case QuestionTypes.Options:
      if (selectedChartType === ChartType.Pie)
        return <OptionSingleGroupPieChart statistics={statistics} height={height} echartsRef={eChartsRef} />

      return (
        <OptionMultiGroupChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          selectedChartType={selectedChartType}
        />
      )
    case QuestionTypes.Number:
      if (selectedChartType === ChartType.Line)
        return (
          <NumberMultiGroupLineChart
            statistics={statistics}
            unit={statistics.unit}
            height={height}
            echartsRef={eChartsRef}
          />
        )

      return (
        <NumberMultiGroupChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          unit={statistics.unit}
          selectedChartType={selectedChartType}
        />
      )
    default:
      return (
        <TextAndFileMultiGroupChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          legendTitle={formatMessage({
            id:
              statistics.questionType === QuestionTypes.File
                ? 'statistics.dataInsights.uploadedAFile'
                : 'statistics.dataInsights.descriptionProvided',
          })}
          selectedChartType={selectedChartType}
        />
      )
  }
}

export default DataInsightsChart
