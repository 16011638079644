import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import DownloadResponse from '@app/src/pages/Questionnaire/DownloadResponse'
import AutoTranslatedAlert from '@app/src/pages/Questionnaire/Provider/Report/AutoTranslatedAlert'
import ProviderReportTranslation from '@app/src/pages/Questionnaire/Provider/Report/ProviderReportTranslation'
import { LanguageInfo } from '@app/src/pages/Questionnaire/ReportContext'
import { Divider, Stack } from '@mui/material'
import React, { useState } from 'react'
import UploadFiles from './UploadFiles'

const ONE_HOUR = 60 * 60 * 1000

export enum AutoAssignTranslationState {
  NoAutoAssignedLanguage = 'NoAutoAssignedLanguage',
  LanguageAutoAssigned = 'LanguageAutoAssigned',
  LanguageAutoAssignedButReverted = 'LanguageAutoAssignedButReverted',
  LanguageAutoAssignedButChangedToSomethingElse = 'LanguageAutoAssignedButChangedToSomethingElse',
}

const ReportHeader: React.FC = () => {
  const [autoAssignTranslationState, setAutoAssignTranslationState] = useState<AutoAssignTranslationState>(
    AutoAssignTranslationState.NoAutoAssignedLanguage,
  )

  const {
    data: availableLanguagesForReport,
    isLoading,
    isError,
  } = useFetchResource<LanguageInfo[]>({
    endpoint: endpoints.availableLanguageTranslation,
    key: FetchKey.Languages,
    options: {
      staleTime: ONE_HOUR,
    },
    shouldShowErrorNotification: false,
  })

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <ProviderReportTranslation
          autoAssignTranslationState={autoAssignTranslationState}
          setAutoAssignTranslationState={setAutoAssignTranslationState}
          availableLanguagesForReport={availableLanguagesForReport}
          isLoading={isLoading}
          isError={isError}
        />
        <Stack direction={{ xs: 'column', sm: 'row', md: 'column', lg: 'row' }} spacing={1} flexWrap="wrap">
          <UploadFiles />
          <DownloadResponse />
        </Stack>
      </Stack>
      <AutoTranslatedAlert
        autoAssignTranslationState={autoAssignTranslationState}
        setAutoAssignTranslationState={setAutoAssignTranslationState}
        availableLanguagesForReport={availableLanguagesForReport}
      />
      <Divider sx={{ mb: 3 }} />
    </>
  )
}

export default ReportHeader
