import EmailPreview from '@app/src/components/ReferralContact/EmailPreview'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { Organization } from '@app/src/types/organizations'
import React from 'react'

interface RequestReminderEmailPreviewProps {
  responder: Organization
  respondersCount: number
}

function RequestReminderEmailPreview({ responder, respondersCount }: RequestReminderEmailPreviewProps) {
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()

  const currentOrg = accessibleOrganizations.find(
    org => org?.organization?.id === account?.organization?.id,
  )?.organization

  const currentOrgName = currentOrg?.name

  if (!responder) return null

  return (
    <EmailPreview
      isReminderEmail
      provider={responder}
      header={{ id: 'form.requestsReminder.emailHeader', values: { currentOrgName, inquiryCount: respondersCount } }}
      description={{
        id: 'form.requestsReminder.emailDescription',
        values: { currentOrgName, providerName: responder.name },
      }}
      ctaText={{ id: 'form.requestsReminder.emailCTA' }}
    />
  )
}

export default RequestReminderEmailPreview
