import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddProvider from '@app/src/components/Drawer/Views/DrawerViewAddProvider'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewImportInitialStep from '@app/src/components/Drawer/Views/DrawerViewImportInitialStep'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import { ProviderUpdateType } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { EmailPurpose, useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useOrganizationCurrency from '@app/src/hooks/organizationCurrency'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { useSelectableRowsWithPostSelectAll } from '@app/src/hooks/useSelectableRows/useSelectableRowsWithPostSelectAll'
import ProviderHeader from '@app/src/pages/ResourceCollection/Collections/Provider/ProviderHeader'
import ProviderRow from '@app/src/pages/ResourceCollection/Collections/Provider/ProviderRow'
import ProvidersFilters from '@app/src/pages/ResourceCollection/Filters/ProvidersFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import getProviderSchema from '@app/src/schemas/provider'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { insertIf } from '@app/src/utils/helpersTs'
import { ResourceTypes } from '@app/src/wf-constants'
import { LabelOutlined, MailOutlined, NotificationsActiveOutlined } from '@mui/icons-material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { capitalize } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import ResourceCollectionScene, { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import ConnectProvidersModal from '../Provider/ConnectProviders/ConnectProvidersModal'
import ImportProvidersModal from '../Provider/ConnectProviders/ImportProvidersModal'
import RemindProvidersModal from '../Provider/ConnectProviders/RemindProvidersModal'

type BaseProviderSceneProps = {
  implicitFilters?: FilterGroup[]
  allowedFilters: string[]
  isConnectedProvidersPage?: boolean
} & Omit<ResourceCollectionSceneProps, 'title'>

const BaseProviderScene: FC<BaseProviderSceneProps> = ({
  implicitFilters = [],
  allowedFilters,
  isConnectedProvidersPage,
  ...props
}) => {
  const { hasPermission } = useAccount()
  const { formatMessage } = useIntl()
  const { solution } = useAuthentication().scope
  const { openDrawer } = useDrawer()
  const location = useLocation<{ fromDashboardCategorizeButton: boolean; selectedCompany: ProviderUpdateType }>()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const resourceType = useCurrentProviderType()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { isConnectDialogOpen, handleOpenConnectDialog, handleCloseConnectDialog, emailPurpose } =
    useConnectProvidersModal()
  const [isImportDialogOpen, openImportDialog, closeImportDialog] = useDialogState(false)
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(
    location?.state?.fromDashboardCategorizeButton,
  )
  const { providerSpend } = useFlags()
  const { organizationCurrency, isLoading: isCurrencyLoading } = useOrganizationCurrency()

  const rawExportPayload = {
    filter: implicitFilters,
    sort: sorting,
    include: [
      'country',
      'ownerUser',
      'creatorUser',
      'creatorOrganization',
      'ownerUser.image',
      'categoryOptions.category',
      'referralContact',
      'referralContact.referralEmailHistory',
      'organization.contacts',
      'organization.contacts.user',
      'spends',
    ],
  }

  const payload = {
    ...rawExportPayload,
    filter: [...rawExportPayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageProviders,
    count,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload,
  })

  const {
    allRows,
    selectedRowsIds,
    setSelectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  } = useSelectableRowsWithPostSelectAll<Provider>({
    initialState: [location?.state?.selectedCompany.providerId ?? []].flat(),
    rowsInPage: pageProviders,
    basePayload: payload,
    fetchKey: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    count,
  })

  const {
    facets: [providersSpendPeriods],
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'spends.periodName' }],
    options: {
      staleTime: 60000,
    },
  })

  const providers = allRows.length ? allRows : pageProviders

  const latestSpendPeriod = useMemo(
    () =>
      providersSpendPeriods
        ?.map(facet => facet.label)
        .sort(comparePeriods)
        .at(-1),
    [providersSpendPeriods, comparePeriods],
  )

  const showCustomCategories = providers.some(provider => Boolean(provider.categoryOptions?.length))
  const showSpend = providerSpend && Boolean(providersSpendPeriods?.length)

  const selectedProvidersByLinkStatus = useMemo(() => {
    const selectedProviders = providers?.filter(provider => selectedRowsIds.includes(provider.id)) ?? []
    return Object.groupBy(selectedProviders, p => p.linkStatus)
  }, [selectedRowsIds.length, providers.length, page, isFetching])

  useEffect(() => {
    setSelectedRowsIds(prev => providers.filter(provider => prev.includes(provider.id)).map(provider => provider.id))
  }, [providers])

  const isFetchingProviders = isFetching || isLoadingAllRows

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'resourceTypes.provider' })}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Provider}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={getExportColumns(getProviderSchema(solution))}
                  rawExportPayload={rawExportPayload}
                  selectedIds={selectedRowsIds}
                />,
              ),
            disabled: isFetchingProviders || !count,
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.import' }),
            variant: 'outlined',
            onClick: () =>
              providerSpend
                ? openDrawer(
                    <DrawerViewImportInitialStep
                      openImportProviderModal={openImportDialog}
                      allowedFilters={allowedFilters}
                      providerType={resourceType}
                      solution={solution}
                      count={count}
                    />,
                  )
                : openImportDialog(),
            disabled: isFetchingProviders || hasPermission(Permissions.TRANSPARENCY_USER),
          },
          {
            label: formatMessage(
              { id: 'resourceCollections.create.addProvider' },
              {
                provider: formatMessage(
                  {
                    id: `general.${capitalize(resourceType)}`,
                  },
                  { count: 1 },
                ).toLowerCase(),
              },
            ),
            variant: 'contained',
            onClick: () => openDrawer(<DrawerViewAddProvider providerType={resourceType} />),
            disabled: isFetchingProviders,
          },
        ]}
        filter={
          <ProvidersFilters allowedFilters={allowedFilters} implicitFilters={implicitFilters} resetPage={resetPage} />
        }
        buttonRow={
          selectedRowsIds.length
            ? [
                ...insertIf<ActionButton>(Boolean(selectedProvidersByLinkStatus[ProviderLinkStatus.Created]), {
                  label: formatMessage(
                    { id: 'resourceCollections.general.connect' },
                    { count: selectedProvidersByLinkStatus[ProviderLinkStatus.Created]?.length ?? 0 },
                  ),
                  startIcon: <MailOutlined />,
                  onClick: () =>
                    handleOpenConnectDialog(
                      selectedProvidersByLinkStatus[ProviderLinkStatus.Created] ?? [],
                      EmailPurpose.InitialInvitation,
                    ),
                  disabled: isFetchingProviders,
                }),
                ...insertIf<ActionButton>(Boolean(selectedProvidersByLinkStatus[ProviderLinkStatus.Invited]), {
                  label: formatMessage(
                    { id: 'resourceCollections.general.sendReminder' },
                    { count: selectedProvidersByLinkStatus[ProviderLinkStatus.Invited]?.length ?? 0 },
                  ),
                  startIcon: <NotificationsActiveOutlined />,
                  onClick: () =>
                    handleOpenConnectDialog(
                      selectedProvidersByLinkStatus[ProviderLinkStatus.Invited] ?? [],
                      EmailPurpose.ExtraReminder,
                    ),
                  disabled: isFetchingProviders,
                }),
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.categorize' },
                    { count: selectedRowsIds.length },
                  ),
                  startIcon: <LabelOutlined />,
                  onClick: openUpdateDrawer,
                  disabled: isFetchingProviders,
                },
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAll' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,
                  disabled: isFetching || isAllSelected(),
                  loading: isLoadingAllRows,
                }),
              ]
            : undefined
        }
        {...props}
      >
        <Table<Provider>
          RowComponent={({ row }) => (
            <ProviderRow
              row={row}
              showCustomCategories={showCustomCategories}
              showSpend={showSpend}
              latestSpendPeriod={latestSpendPeriod}
              onCheckboxChange={handleCheckboxChange}
              selectedProviderIds={selectedRowsIds}
              organizationCurrency={organizationCurrency}
            />
          )}
          HeaderComponent={() => (
            <ProviderHeader
              showCustomCategories={showCustomCategories}
              showSpend={showSpend}
              latestSpendPeriod={latestSpendPeriod}
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              isHeaderChecked={isHeaderChecked}
              handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            />
          )}
          data={pageProviders}
          isLoading={isFetching || isCurrencyLoading}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
      <UpdateProvidersDrawer
        selectedProviders={providers
          .filter(provider => selectedRowsIds.includes(provider.id))
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedRowsIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
      <ImportProvidersModal onClose={closeImportDialog} open={isImportDialogOpen} />
      <CreationModalProgressContextProvider>
        {emailPurpose === EmailPurpose.InitialInvitation ? (
          <ConnectProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
        ) : (
          <RemindProvidersModal onClose={handleCloseConnectDialog} open={isConnectDialogOpen} />
        )}
      </CreationModalProgressContextProvider>
    </>
  )
}

export default BaseProviderScene
