import endpoints from '@app/src/api/endpoints'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { NavbarUser, useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useLocale } from '@app/src/context/LocaleContext'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import useLanguageSelection from '@app/src/hooks/useLanguageSelection'
import { AmplitudeTrackingEvents, ResourceTypes, Solutions } from '@app/src/wf-constants'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'

const OnboardingLanguageSelector: React.FC = () => {
  const { account } = useAccount()
  const { availableLanguageOptions, hideLanguageSelector } = useLanguageSelection()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const user = account?.user
  const { mutateAsync: saveUserAsync } = useUpdateResource<Partial<NavbarUser>>()
  const { locale, setLocale } = useLocale()
  const selectedLanguage = locale ? availableLanguageOptions.find(l => l.localeName === locale) : undefined
  const otherLanguages = availableLanguageOptions.filter(l => l.localeName !== locale)
  const { trackEvent } = useAmplitude()

  const handleUserSettingsSubmit = async (language: string): Promise<void | JSX.Element> => {
    const editedUser = { ...(user || {}), culture: language }

    await saveUserAsync({ url: endpoints.saveResource(Solutions.Resources, ResourceTypes.User), body: editedUser })

    if (editedUser?.culture !== locale) {
      trackEvent({
        name: AmplitudeTrackingEvents.Onboarding.UserInfo.LanguageChanged,
        eventProps: {
          page: 'Onboarding',
          user_id: account?.user?.id,
          language: editedUser?.culture,
          organization_id: account?.organization?.id,
          organization_name: account?.organization?.name,
        },
      })
    }
  }

  const handleChangeLanguage = async (language: string) => {
    setLocale(language)
    closeMenu()
    await handleUserSettingsSubmit(language)
  }

  if (hideLanguageSelector) {
    return null
  }

  return (
    <>
      <Button
        startIcon={<ReactCountryFlag countryCode={selectedLanguage?.flagCode} svg style={{ fontSize: '2em' }} />}
        onClick={openMenu}
      >
        {selectedLanguage?.shortLabel}
        <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={closeMenu}
        sx={({ zIndex }) => ({
          zIndex: zIndex.modal + 2,
        })}
      >
        {otherLanguages.map(language => (
          <MenuItem key={language.localeName} onClick={() => handleChangeLanguage(language.localeName)}>
            <ListItemIcon>
              <ReactCountryFlag countryCode={language.flagCode} svg style={{ fontSize: '2em' }} />
            </ListItemIcon>
            {language.shortLabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default OnboardingLanguageSelector
