import Permissions, { usePermissions } from '@app/src/auth/permissions'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import ProviderSpendClassificationCell from '@app/src/components/Table/Cells/ProviderSpendClassificationCell'
import ReferralContactCell from '@app/src/components/Table/Cells/ReferralContactCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import UserGroupCell from '@app/src/components/Table/Cells/UserGroupCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import { ExternalVerificationStatus, Inquiry } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import markdownToTxt from 'markdown-to-txt'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

interface AccessorInquiryRowProps {
  row: Inquiry
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, inquiry: Inquiry) => void
  selectedInquiriesIds?: Array<number>
  showProviderSpend?: boolean
}

export const AccessorInquiryRow: React.FC<AccessorInquiryRowProps> = ({
  row: inquiry,
  selectedInquiriesIds,
  onCheckboxChange,
  showProviderSpend,
}) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const verificationPermitted = hasPermission(Permissions.WRITE_VERIFICATION)

  const isChecked = Boolean(selectedInquiriesIds?.find(inquiryId => inquiryId === inquiry.id))

  const latestResponse = inquiry.request?.responses?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )[0]

  return (
    <TableRow selected={isChecked}>
      {onCheckboxChange && <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, inquiry)} />}
      <ExternalVerificationStatusCell
        responseStatus={
          latestResponse?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet
        }
        draftStatus={latestResponse?.draftStatus}
        inquiryStatus={inquiry?.status}
      />
      <RequestCell
        title={inquiry.template.title}
        imageUrl={inquiry.template?.image?.url}
        onClick={
          inquiry.request
            ? () => history.push({ pathname: reporting, search: `requestId=${inquiry.request?.id}` })
            : undefined
        }
        tooltipText={
          inquiry.request
            ? undefined
            : formatMessage({ id: 'schemas.request.guidance.cannotGoToRequestUntilItHasBeenAnswered' })
        }
      />
      <PeriodNameCell periodName={inquiry.periodName} />
      {inquiry.providerId && <CompanyCell company={inquiry.provider} drawer />}
      {showProviderSpend && <ProviderSpendClassificationCell spends={inquiry.provider?.spends} />}
      <DateCell value={inquiry.createdAt} />
      <DateCell value={inquiry.deadline} />
      <DateCell value={latestResponse?.submittedAt} />
      {inquiry.provider.linkStatus === ProviderLinkStatus.Connected ? (
        <UserGroupCell
          users={inquiry.provider.organization.contacts?.map(contact => contact.user)}
          subTitle={inquiry.provider.name}
          drawer
        />
      ) : (
        <ReferralContactCell provider={inquiry.provider} drawer />
      )}
      {verificationPermitted && (
        <>
          <ValueCell
            value={
              latestResponse?.verifications?.[0]?.externalVerificationComment &&
              markdownToTxt(latestResponse?.verifications?.[0]?.externalVerificationComment)
            }
          />
          <StatusCell verification value={latestResponse?.verifications?.[0]?.internalVerificationStatus} />
          <ValueCell
            value={
              latestResponse?.verifications?.[0]?.internalVerificationComment &&
              markdownToTxt(latestResponse?.verifications?.[0]?.internalVerificationComment)
            }
          />
        </>
      )}
    </TableRow>
  )
}
