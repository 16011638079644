import {
  deDE as mui_deDE,
  enUS as mui_enUS,
  esES as mui_esES,
  Localization,
  nbNO as mui_nbNO,
  svSE as mui_svSE,
  zhCN as mui_zhCN,
} from '@mui/material/locale'
import {
  de as dateFns_de,
  enUS as dateFns_enUS,
  es as dateFns_es,
  nb as dateFns_nb,
  sv as dateFns_sv,
  zhCN as dateFns_zhCN,
} from 'date-fns/locale'

export interface LanguageInfo {
  jsonFileName: string
  localeName: string
  shortLabel: string
  label: string
  flagCode: string
  azureTranslateLanguageCode: string //See https://learn.microsoft.com/en-us/azure/ai-services/translator/language-support
  muiLocale: Localization
  dateFnsLocale: Locale
}

export const SUPPORTED_LANGUAGES: LanguageInfo[] = [
  {
    jsonFileName: 'en',
    localeName: 'en-US',
    shortLabel: 'EN',
    label: 'English',
    flagCode: 'GB',
    azureTranslateLanguageCode: 'en',
    muiLocale: mui_enUS,
    dateFnsLocale: dateFns_enUS,
  },
  {
    jsonFileName: 'sv',
    localeName: 'sv-SE',
    shortLabel: 'SV',
    label: 'Svenska',
    flagCode: 'SE',
    azureTranslateLanguageCode: 'sv',
    muiLocale: mui_svSE,
    dateFnsLocale: dateFns_sv,
  },
  {
    jsonFileName: 'de',
    localeName: 'de',
    shortLabel: 'DE',
    label: 'Deutsch',
    flagCode: 'DE',
    azureTranslateLanguageCode: 'de',
    muiLocale: mui_deDE,
    dateFnsLocale: dateFns_de,
  },
  {
    jsonFileName: 'es',
    localeName: 'es-ES',
    shortLabel: 'ES',
    label: 'Español',
    flagCode: 'ES',
    azureTranslateLanguageCode: 'es',
    muiLocale: mui_esES,
    dateFnsLocale: dateFns_es,
  },
  {
    jsonFileName: 'zh',
    localeName: 'zh-CN',
    shortLabel: '中文',
    label: '中文 (简体)',
    flagCode: 'CN',
    azureTranslateLanguageCode: 'zh-Hans',
    muiLocale: mui_zhCN,
    dateFnsLocale: dateFns_zhCN,
  },
  {
    jsonFileName: 'no',
    localeName: 'no',
    shortLabel: 'NO',
    label: 'Norsk',
    flagCode: 'NO',
    azureTranslateLanguageCode: 'no',
    muiLocale: mui_nbNO,
    dateFnsLocale: dateFns_nb,
  },
].sort((a, b) => a.label.localeCompare(b.label, 'en-US'))
