import endpoints from '@app/src/api/endpoints'
import { useUpdateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { NavbarUser, useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import LanguageSelector from '@app/src/pages/UserSettingsScene/LanguageSelector'
import { AmplitudeTrackingEvents, NotificationSeverity, ResourceTypes, Solutions } from '@app/src/wf-constants'
import { Alert, Grid, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const UserSettingsForm = (): JSX.Element => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()

  const { account, fetchAccount } = useAccount()
  const { handleSubmit, formState, setError, register, setValue, control } = useForm()
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { mutate, isLoading: isSubmitting } = useUpdateResource<Partial<NavbarUser>>()
  const { trackEvent } = useAmplitude()
  const user = account?.user

  const handleUserSettingsSubmit = async (values: {
    [key: string]: Partial<NavbarUser>
  }): Promise<void | JSX.Element> => {
    const editedUser = { ...(user || {}), ...values } //the backend needs the whole user object along with the edited values

    mutate(
      { url: endpoints.saveResource(Solutions.Resources, ResourceTypes.User), body: editedUser },
      {
        onSuccess: async () => {
          await fetchAccount()
          showSnackbar({
            message: formatMessage({ id: 'userSettings.settingsUpdated' }),
            severity: NotificationSeverity.success,
          })

          if (user?.culture !== editedUser?.culture) {
            trackEvent({
              name: AmplitudeTrackingEvents.Onboarding.UserInfo.LanguageChanged,
              eventProps: {
                page: 'UserSettings',
                user_id: account?.user?.id,
                language: editedUser?.culture,
                organization_id: account?.organization?.id,
                organization_name: account?.organization?.name,
              },
            })
          }
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
          }
        },
      },
    )
  }

  if (!user) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} height={60} />
        ))}
      </>
    )
  }

  const errors = formState.errors

  return (
    <form onSubmit={handleSubmit(handleUserSettingsSubmit)} noValidate>
      <Grid container spacing={3}>
        {Object.keys(formState.errors)?.length > 0 && (
          <Grid item xs={12}>
            <Alert elevation={1} variant="filled" severity="error">
              {formatMessage({ id: 'form.validation.error' })}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.userFirstName' })}
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name="givenName"
            label={formatMessage({ id: 'schemas.user.givenName' })}
            error={Boolean(errors?.givenName)}
            type="text"
            onClear={(): void => setValue('givenName', '')}
            helperText={errors?.givenName?.message}
            defaultValue={user.givenName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.userLastName' })}
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name="familyName"
            label={formatMessage({ id: 'schemas.user.familyName' })}
            error={Boolean(errors?.familyName)}
            type="text"
            onClear={(): void => setValue('familyName', '')}
            helperText={errors?.familyName?.message}
            defaultValue={user.familyName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.userJobTitle' })}
            inputRef={register}
            name="position"
            label={formatMessage({ id: 'schemas.user.position' })}
            error={Boolean(errors?.position)}
            type="text"
            onClear={(): void => setValue('position', '')}
            helperText={errors?.position?.message}
            defaultValue={user.position}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.userPhoneNumber' })}
            inputRef={register}
            name="phoneNumber"
            label={formatMessage({ id: 'schemas.user.phoneNumber' })}
            error={Boolean(errors?.phoneNumber)}
            type="text"
            onClear={(): void => setValue('phoneNumber', '')}
            helperText={errors?.phoneNumber?.message}
            defaultValue={user.phoneNumber}
          />
        </Grid>
        <LanguageSelector control={control} />
        <Grid item xs={12} className={classes.submitButton}>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            {formatMessage({ id: 'general.save' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default UserSettingsForm
