import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProductMappingResponse } from '@app/src/types/product'
import React from 'react'
import { useIntl } from 'react-intl'
import ReceivedMappingResponseFilters from '../Filters/ReceivedMappingResponseFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ReceivedMappingResponseHeader from './MappingResponse/ReceivedMappingResponseHeader'
import ReceivedMappingResponseRow from './MappingResponse/ReceivedMappingResponseRow'

const allowedFilters: string[] = [
  'productMappingRequest.product.id',
  'productMappingRequest.product.name',
  'status',
  'productMappingRequest.periodName',
  'productMappingRequest.product.provider.spends.spendClassification',
  'productMappingRequest.product.provider.spends.periodName',
]

const ReceivedMappingResponseCollection: React.FC = () => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const filters = useGetApiQueryFilters(allowedFilters)
  const implicitFilters: FilterGroup[] = [
    {
      name: 'productMappingRequest.creatorOrganizationId',
      filters: [{ value: account?.organization?.id, operator: Operators.EqualTo }],
    },
  ]

  const {
    items: mappingResponses,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<ProductMappingResponse>({
    key: FetchKey.MappingResponseCollection,
    endpoint: endpoints.mappingResponseCollection,
    payload: {
      filter: [...filters, ...implicitFilters],
      sort: sorting,
      include: ['creatorOrganization', 'productMappingRequest.product'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.allMappingResponses' })}
      filter={<ReceivedMappingResponseFilters allowedFilters={allowedFilters} implicitFilters={implicitFilters} />}
    >
      <Table<ProductMappingResponse>
        RowComponent={ReceivedMappingResponseRow}
        HeaderComponent={() => <ReceivedMappingResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        data={mappingResponses}
        count={count}
        page={page}
        isError={isError}
        isLoading={isLoading || isFetching}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ReceivedMappingResponseCollection
