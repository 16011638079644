import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { filtersWithoutTemplateIdAndPeriod } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionnaireTemplate, Response } from '@app/src/types/resourceExplorer'
import { BubbleChartOutlined } from '@mui/icons-material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '..'
import DataHubInsightsDetailsHeader from './DataHubInsightsDetailsHeader'
import DataHubInsightsDetailsRow from './DataHubInsightsDetailsRow'

export const transformResponseFilterNames = (filter: FilterGroup[]) =>
  filter
    .filter(filter => filter.name !== 'requestItem.questionId')
    .map(filter => {
      const responseFilter = filter.name.startsWith('response')
      const requestItemTemplateFilter = filter.name.startsWith('requestItem.template')

      return responseFilter
        ? { ...filter, name: filter.name.replace('response.', '') }
        : requestItemTemplateFilter
          ? { ...filter, name: filter.name.replace('requestItem.template.', 'request.template.sections.questions.') }
          : filter
    })

const DataHubInsightsDetails: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 50 })
  const filters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { formatMessage } = useIntl()

  const cleanFilters = filtersWithoutTemplateIdAndPeriod(filters)
  const renamedFilters = transformResponseFilterNames(cleanFilters)
  const templateIds = filters.find(filter => filter.name === 'response.request.questionnaireTemplateId')?.filters[0]
    .value
  const periodNames = filters.find(filter => filter.name === 'response.request.periodName')?.filters[0].value

  const isSinglePeriodSelected = Array.isArray(periodNames) ? periodNames.length === 1 : false
  const isSingleTemplateIdSelected = Array.isArray(templateIds) ? templateIds.length === 1 : false

  const {
    items: responses,
    isLoading,
    count,
    isError,
  } = useFetchCollectionWithPost<Response>({
    endpoint: endpoints.responses,
    key: FetchKey.Response,
    payload: {
      filter: [...renamedFilters, RESPONSE_LATEST_SUBMITTED_FILTER],
      sort: sorting,
      include: [
        'responseAnalytics',
        'requestItem',
        'request',
        'request.template',
        'request.subscriptions.target',
        'items.requestItem.unit.symbol',
        'items.flagRule.creatorOrganization',
        'items.flagRule.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
        'items.flagRule.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
      ],
      pagination: {
        itemsPerPage: pageSize,
        pageNumber: page,
      },
    },
    options: { enabled: isSinglePeriodSelected && isSingleTemplateIdSelected },
  })

  const { items: allTemplates, isLoading: isAllTemplatesLoading } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    key: [FetchKey.RequestForAllTemplates],
    endpoint: endpoints.worldfavorAndCustomTemplates,
    payload: {
      include: ['sections.questions'],
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: templateIds,
              operator: Operators.In,
            },
          ],
        },
      ],
    },
    options: { enabled: isSingleTemplateIdSelected },
  })

  const requestItems = useMemo(() => responses?.[0]?.items?.map(item => item.requestItem), [responses])
  const requestSections = allTemplates?.[0]?.sections

  if (!isSinglePeriodSelected || !isSingleTemplateIdSelected || !requestSections) {
    return (
      <EmptyState
        title={formatMessage({ id: 'statistics.dataInsights.detailsTable.emptyStateTitle' })}
        description={formatMessage({ id: 'statistics.dataInsights.detailsTable.emptyStateDescription' })}
        iconComponent={BubbleChartOutlined}
      />
    )
  }

  return (
    <Table<Response>
      HeaderComponent={() => (
        <DataHubInsightsDetailsHeader
          requestItems={requestItems}
          toggleSorting={toggleSorting}
          activeSorting={sorting}
        />
      )}
      RowComponent={({ row }) => <DataHubInsightsDetailsRow row={row} sections={requestSections} />}
      count={count}
      data={responses}
      isLoading={isLoading || isAllTemplatesLoading}
      isError={isError}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      stickyColumnIndex={1}
    />
  )
}

export default DataHubInsightsDetails
