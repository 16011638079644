import useLocalisedCountryName from '@app/src/hooks/useLocalisedCountryName'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import { AutocompleteGetTagProps, AutocompleteRenderOptionState, Chip } from '@mui/material'
import React from 'react'

const useLocalisedCountryFilter = () => {
  const { getLocalisedCountryName } = useLocalisedCountryName()

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: FacetItem,
    state: AutocompleteRenderOptionState,
  ) => {
    const localizedCountryName = option?.label ? getLocalisedCountryName(option.label) : '-'

    return (
      <OptionWithLabel
        key={option.label}
        props={props}
        label={localizedCountryName || option?.label}
        multiple
        selected={state.selected}
      />
    )
  }

  const renderTags = (value: FacetItem[], getTagProps: AutocompleteGetTagProps) => {
    return value.map((option, index) => {
      const localizedCountryName = option.label ? getLocalisedCountryName(option.label) : '-'

      return (
        <Chip
          {...getTagProps({ index })}
          key={option.label}
          label={localizedCountryName || option.label}
          size="small"
        />
      )
    })
  }

  return { renderOption, renderTags }
}

export default useLocalisedCountryFilter
