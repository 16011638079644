import endpoints from '@app/src/api/endpoints'

import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import WfLoader from '@app/src/components/WfLoader'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useLocale } from '@app/src/context/LocaleContext'
import ErrorIcon from '@mui/icons-material/Error'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import AccountContext, { Account, AccountContextProps } from './AccountContext'

type AccountContextProviderProps = {
  children: ReactNode
}

const mapPermissions = (permissions?: Account['permissions']): Permissions[] => {
  return permissions ? permissions?.map(perm => perm as Permissions) : []
}

const AccountContextProvider: FC<AccountContextProviderProps> = ({ children }: AccountContextProviderProps) => {
  const { scope } = useAuthentication()
  const { setLocale } = useLocale()
  const { formatMessage } = useIntl()
  const {
    data: account,
    isLoading,
    error,
    refetch: refetchAccounts,
  } = useFetchResource<AccountContextProps['account']>({
    endpoint: endpoints.navbar,
    key: FetchKey.Account,
    options: { retry: 3 },
  })

  const [permissions, setPermissions] = useState<string[]>(() => mapPermissions(account?.permissions))

  const fetchAccount = async (): Promise<void> => {
    await refetchAccounts()
  }

  const culture = account?.user?.culture

  useEffect(() => {
    if (culture) {
      setLocale(culture)
    }
  }, [culture])

  const hasPermission = (permissionsToVerify: Permissions | Permissions[]): boolean => {
    if (Array.isArray(permissionsToVerify)) {
      return Boolean(permissionsToVerify.some(perm => permissions.includes(perm)))
    }
    return Boolean(permissions.includes(permissionsToVerify))
  }

  useEffect(
    () => setPermissions(mapPermissions(account?.permissions)),
    [scope.role, scope.solution, scope.organization, scope, account],
  )

  return (
    <AccountContext.Provider value={{ account, isLoading, fetchAccount, hasPermission }}>
      {error ? (
        <EmptyState
          variant={EmptyStateVariant.Error}
          title={formatMessage({ id: 'loadError.title' })}
          description={formatMessage({ id: 'loadError.description' })}
          iconComponent={ErrorIcon}
          sx={{ mt: 6 }}
        />
      ) : isLoading ? (
        <WfLoader />
      ) : (
        children
      )}
    </AccountContext.Provider>
  )
}

export default AccountContextProvider
