import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import TextField from '@app/src/components/Ui/TextField'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { Add } from '@mui/icons-material'
import { Box, Button, Grid, Stack } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import EditSection from './EditSection'
import { FormDataSection } from './TemplateBuilder'

type EditTemplateContentProps = {
  template?: QuestionnaireTemplate
}

const EditTemplateContent: React.FC<EditTemplateContentProps> = ({ template }) => {
  const { formatMessage } = useIntl()
  const { control, register, errors } = useFormContext()
  const { fields, append, remove, move } = useFieldArray<FormDataSection>({
    control,
    name: 'sections',
  })

  return (
    <Box mb={3} pr={3} flexGrow={1}>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Stack spacing={4} mt={6}>
            <TextField
              hoveringLabel
              fullWidth
              name="title"
              defaultValue={template?.title}
              placeholder={formatMessage({ id: 'templateBuilder.fields.questionnaireName.placeholder' })}
              label={formatMessage({ id: 'templateBuilder.fields.questionnaireName.title' })}
              size="small"
              error={Boolean(errors?.title)}
              helperText={errors?.title?.message}
              inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
              InputLabelProps={{ required: true }}
              inputProps={{
                maxLength: 500,
              }}
            />
            <ControlledTextFieldWithFormatting
              name="description"
              defaultValue={template?.description}
              control={control}
              label={formatMessage({ id: 'templateBuilder.fields.questionnaireDescription.title' })}
              placeholder={formatMessage({ id: 'templateBuilder.fields.questionnaireDescription.placeholder' })}
              error={Boolean(errors?.description)}
              helperText={errors?.description?.message}
              multiline
              size="small"
            />
            {fields?.map((section, index) => (
              <React.Fragment key={section.id}>
                <EditSection
                  section={section}
                  templateSection={template?.sections?.find(t => t.id === Number.parseInt(section.sectionId ?? '0'))}
                  index={index}
                  removeSection={() => remove(index)}
                  moveUp={() => move(index, index - 1)}
                  moveDown={() => move(index, index + 1)}
                  sectionCount={fields.length}
                  templateTitle={template?.title}
                />
              </React.Fragment>
            ))}

            <Box bgcolor="common.white" py={5} px={6} textAlign="center" borderRadius={2}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() =>
                  append({
                    questions: [],
                    description: '',
                    title: '',
                    sectionId: '0',
                  })
                }
                data-testid="add-section"
              >
                {formatMessage({ id: 'templateBuilder.addSection' })}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditTemplateContent
