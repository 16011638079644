import { FetchKey } from '@app/src/api/fetchHooks'
import { SortOrder } from '@app/src/types/filter'
import { Chip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import Filter from './Filter'
import FilterFacetSelect from './FilterFacetSelect'
import FilterSection from './FilterSection'
import LevelFilter from './LevelFilter'
import OptionWithLabel from './OptionWithLabel'
import { FilterGroup, Operators } from './useFilters'

interface AssessmentSubFilterProps {
  effectiveImplicitFilters: FilterGroup[]
  canAccessAssessmentTemplate: boolean
  assessments: boolean
  assessmentFilterNamePrefix?: string
  fetchKey: FetchKey
  endpoint: string
  assessmentType?: string
}

const AssessmentSubFilter: React.FC<AssessmentSubFilterProps> = ({
  effectiveImplicitFilters,
  canAccessAssessmentTemplate,
  assessments,
  assessmentFilterNamePrefix,
  fetchKey,
  endpoint,
  assessmentType,
}) => {
  const { formatMessage } = useIntl()

  const templateNameFilter: FilterGroup = {
    name: `${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}assessmentTemplate.AssessmentType`,
    filters: [{ value: ['BaselineAssessment'], operator: Operators.In }],
  }

  const excludeTemplateNameFilter: FilterGroup = {
    name: `${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}assessmentTemplate.AssessmentType`,
    filters: [{ value: ['BaselineAssessment'], operator: Operators.NotIn }],
  }

  return canAccessAssessmentTemplate ? (
    <FilterSection label={formatMessage({ id: 'schemas.filter.assessments' })} defaultCollapsed>
      {assessments && assessmentType !== 'Baseline' && (
        <>
          <Typography color="textSecondary" variant="subtitle2">
            {formatMessage({ id: 'assessment.filter.allAssessments' })}
          </Typography>
          <Filter
            operator={Operators.In}
            name={`${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}assessmentTemplate.name`}
          >
            {({ value, onChange, filterName }) => (
              <FilterFacetSelect
                implicitFilters={[...effectiveImplicitFilters, excludeTemplateNameFilter]}
                size="small"
                multiple
                facetsParam={{
                  key: [fetchKey, filterName],
                  endpoint: endpoint,
                  facetsParam: [{ name: filterName, isEnum: true }],
                  sort: {
                    target: 'label',
                    order: SortOrder.ASCENDING,
                  },
                }}
                filterName={filterName}
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'assessment.filter.name' })}
                value={[value].flat()}
              />
            )}
          </Filter>
          <Filter
            operator={Operators.In}
            name={`${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}intervalType`}
          >
            {({ value, onChange, filterName }) => (
              <FilterFacetSelect
                implicitFilters={[...effectiveImplicitFilters, excludeTemplateNameFilter]}
                size="small"
                multiple
                facetsParam={{
                  key: [fetchKey, filterName],
                  endpoint: endpoint,
                  facetsParam: [{ name: filterName, isEnum: true }],
                  sort: {
                    target: 'label',
                    order: SortOrder.ASCENDING,
                  },
                }}
                renderOption={(props, option, { selected }) => (
                  <OptionWithLabel
                    key={option.label}
                    props={props}
                    label={formatMessage(
                      { id: option?.label ? `assessment.intervalTypeValues.${option.label}` : '' },
                      { value: option?.label },
                    )}
                    multiple
                    selected={selected}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.value.toString() || index}
                          label={formatMessage(
                            { id: option?.label ? `assessment.intervalTypeValues.${option.label}` : '' },
                            { value: option?.label },
                          )}
                          size="small"
                        />
                      ),
                  )
                }
                filterName={filterName}
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'assessment.filter.result' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </>
      )}
      <Typography color="textSecondary" variant="subtitle2">
        {formatMessage({ id: 'baseAssessment.filter.baseline' })}
      </Typography>
      <Filter
        name={`${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}totalLevel`}
        operator={Operators.In}
      >
        {({ value, onChange, filterName }) => (
          <FilterFacetSelect
            implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
            multiple
            facetsParam={{
              key: [fetchKey, filterName],
              endpoint: endpoint,
              facetsParam: [{ name: filterName, isEnum: true }],
              sort: { target: 'label', order: SortOrder.DESCENDING },
            }}
            renderOption={(props, option, { selected }) => (
              <OptionWithLabel
                key={option.label}
                props={props}
                label={formatMessage(
                  { id: option?.label ? `baseAssessment.levelN` : 'general.notAvailable' },
                  { value: option?.label },
                )}
                multiple
                selected={selected}
              />
            )}
            renderTags={(value, getTagProps) =>
              value?.map(
                (option, index) =>
                  option && (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.value.toString() || index}
                      label={formatMessage(
                        { id: option?.label ? `baseAssessment.levelN` : 'general.notAvailable' },
                        { value: option?.label },
                      )}
                      size="small"
                    />
                  ),
              )
            }
            filterName={filterName}
            onChange={value => onChange(value)}
            fieldLabel={formatMessage({ id: 'baseAssessment.filter.level' })}
            value={[value].flat()}
          />
        )}
      </Filter>
      <LevelFilter
        filterName="section1Level"
        fieldLabel={formatMessage({ id: 'baseAssessment.filter.topicGeneral' })}
        assessmentFilterNamePrefix={assessmentFilterNamePrefix}
        implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
        fetchKey={fetchKey}
        endpoint={endpoint}
      />
      <LevelFilter
        filterName="section2Level"
        fieldLabel={formatMessage({ id: 'baseAssessment.filter.topicEnvironmental' })}
        assessmentFilterNamePrefix={assessmentFilterNamePrefix}
        implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
        fetchKey={fetchKey}
        endpoint={endpoint}
      />
      <LevelFilter
        filterName="section3Level"
        fieldLabel={formatMessage({ id: 'baseAssessment.filter.topicSocial' })}
        assessmentFilterNamePrefix={assessmentFilterNamePrefix}
        implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
        fetchKey={fetchKey}
        endpoint={endpoint}
      />
      <LevelFilter
        filterName="section4Level"
        fieldLabel={formatMessage({ id: 'baseAssessment.filter.topicGovernance' })}
        assessmentFilterNamePrefix={assessmentFilterNamePrefix}
        implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
        fetchKey={fetchKey}
        endpoint={endpoint}
      />
      <LevelFilter
        filterName="section5Level"
        fieldLabel={formatMessage({ id: 'baseAssessment.filter.topicSupplychain' })}
        assessmentFilterNamePrefix={assessmentFilterNamePrefix}
        implicitFilters={[...effectiveImplicitFilters, templateNameFilter]}
        fetchKey={fetchKey}
        endpoint={endpoint}
      />
    </FilterSection>
  ) : null
}

export default AssessmentSubFilter
