import { useLocale } from '@app/src/context/LocaleContext'
import { getAzureLanguageCorrespondingToLocale } from '@app/src/pages/Questionnaire/Provider/Report/ProviderReportTranslation'
import { LanguageInfo, useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { SUPPORTED_LANGUAGES } from '@app/src/wf-constants/languages'
import { TranslateOutlined } from '@mui/icons-material'
import { Alert, Box, Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { AutoAssignTranslationState } from './ReportHeader'

interface AutoTranslatedAlertProps {
  autoAssignTranslationState: AutoAssignTranslationState
  setAutoAssignTranslationState: React.Dispatch<React.SetStateAction<AutoAssignTranslationState>>
  availableLanguagesForReport?: LanguageInfo[]
}

const AutoTranslatedAlert: React.FC<AutoTranslatedAlertProps> = ({
  autoAssignTranslationState,
  setAutoAssignTranslationState,
  availableLanguagesForReport,
}) => {
  const { formatMessage } = useIntl()
  const { setReportLocale } = useReport()
  const { locale: platformLocale } = useLocale()

  if (autoAssignTranslationState === AutoAssignTranslationState.LanguageAutoAssigned)
    return (
      <Alert severity="info">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {formatMessage({ id: 'reporting.translation.autoTranslatedText' })}
          <Button
            variant="text"
            onClick={() => {
              setReportLocale(null)
              setAutoAssignTranslationState(AutoAssignTranslationState.LanguageAutoAssignedButReverted)
            }}
          >
            {formatMessage({ id: 'reporting.translation.showOriginal' })}
          </Button>
        </Box>
      </Alert>
    )

  if (autoAssignTranslationState === AutoAssignTranslationState.LanguageAutoAssignedButReverted) {
    const myLanguageName = SUPPORTED_LANGUAGES.find(l => l.localeName === platformLocale)?.label
    return (
      <Alert severity="info" variant="outlined">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {formatMessage({ id: 'reporting.translation.displayInMyLanguage' }, { languageName: myLanguageName })}
          <Button
            variant="text"
            startIcon={<TranslateOutlined />}
            onClick={() => {
              const azureLanguageCorrespondingToLocale = getAzureLanguageCorrespondingToLocale(
                platformLocale,
                availableLanguagesForReport ?? [],
              )
              setReportLocale(azureLanguageCorrespondingToLocale ?? null)
              setAutoAssignTranslationState(AutoAssignTranslationState.LanguageAutoAssigned)
            }}
          >
            {formatMessage({ id: 'reporting.translation.translate' })}
          </Button>
        </Box>
      </Alert>
    )
  }
}

export default AutoTranslatedAlert
