import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import useLocalisedCountryName from '@app/src/hooks/useLocalisedCountryName'
import {
  GenericOrganization,
  Location,
  Provider,
  ProviderLinkStatus,
  RiskTableView,
} from '@app/src/types/organizations'
import { Country, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { randomizeDecimal } from '@app/src/utils'
import { numberIsSet } from '@app/src/utils/helpers'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Button, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Palette, useTheme } from '@mui/material/styles'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { EffectScatterChart, MapChart } from 'echarts/charts'
import {
  GeoComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { GraphSeriesOption, TopLevelFormatterParams } from 'echarts/types/dist/shared'
import { GeoStateOption } from 'echarts/types/src/coord/geo/GeoModel'
import { GeoJSONSourceInput } from 'echarts/types/src/coord/geo/geoTypes'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQuery, UseQueryResult } from 'react-query'
import { LocationTypes } from './CountryRiskSection'

interface RiskPerCountrySeriesData {
  name: string
  value?: number
  countryName: string
  riskStatus?: RiskStatus
  itemStyle?: GeoStateOption['itemStyle']
  emphasis?: GraphSeriesOption['emphasis'] & { show: boolean }
}

const useWorldMap = (): UseQueryResult<GeoJSONSourceInput> =>
  useQuery('map', async () => await fetch('/world-map-light.json').then(response => response.text()))

interface CountryRiskMapProps {
  activeRisk?: RiskType
  riskScreening: RiskTableView[]
  selectedRiskId: number
  countries: Country[]
  selectedLocationType: string
  height?: number
}

export const getMapPieces = (palette: Palette, activeRisk?: RiskType) => {
  if (!activeRisk) {
    return
  }

  return [
    {
      min: 0,
      max: activeRisk.extremeLimit,
      color: palette.semantic.error,
    },
    {
      min: activeRisk.extremeLimit + 0.01,
      max: activeRisk.highLimit,
      color: palette.error.dark,
    },
    {
      min: activeRisk.highLimit + 0.01,
      max: activeRisk.mediumLimit,
      color: palette.warning.dark,
    },
    {
      min: activeRisk.mediumLimit + 0.01,
      max: 100,
      color: palette.info.dark,
    },
  ]
}

const CountryRiskMap: React.FC<CountryRiskMapProps> = ({
  activeRisk,
  riskScreening,
  selectedRiskId,
  countries,
  selectedLocationType,
  height = 500,
}) => {
  const { data: worldMap } = useWorldMap()
  const { formatMessage } = useIntl()
  const [currentProvider, setCurrentProvider] = useState<Provider | undefined>()
  const [currentCountry, setCurrentCountry] = useState<RiskPerCountrySeriesData | undefined>()
  const [expanded, setExpanded] = useState(false)
  const { getLocalisedCountryName } = useLocalisedCountryName()
  const { palette, wfShadows } = useTheme()
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    SVGRenderer,
    LegendComponent,
    GeoComponent,
    VisualMapComponent,
    MapChart,
    EffectScatterChart,
  ])

  useEffect(() => {
    setCurrentProvider(undefined)
    setCurrentCountry(undefined)
    setExpanded(false)
  }, [selectedRiskId, selectedLocationType])

  const mapToChartDatapoint = useCallback(
    (provider: GenericOrganization, country?: Country, locations?: Location[]) => {
      if (!country) return undefined

      const localisedCountryName = getLocalisedCountryName(country.countryCode)
      const location = locations?.[0]
      if (numberIsSet(location?.longitude) && numberIsSet(location?.latitude)) {
        return {
          name: provider.name,
          value: [location.longitude, location.latitude, 0],
          id: provider.id,
          countryName: localisedCountryName,
          countryCode: country.countryCode,
        }
      }

      return {
        name: provider.name,
        value: [randomizeDecimal(country.longitude), randomizeDecimal(country.latitude), 0],
        id: provider.id,
        countryName: localisedCountryName,
        countryCode: country.countryCode,
      }
    },
    [getLocalisedCountryName],
  )

  const providersChartData = useMemo(() => {
    return riskScreening.flatMap(({ parentObject }) => {
      const isConnected = parentObject.linkStatus === ProviderLinkStatus.Connected

      if (selectedLocationType === LocationTypes.HQ) {
        if (isConnected) {
          return mapToChartDatapoint(parentObject, parentObject.country, parentObject.organization?.locations) ?? []
        }

        return mapToChartDatapoint(parentObject, parentObject.country, parentObject.locations) ?? []
      }

      if (
        selectedLocationType === LocationTypes.OWNED_OPERATIONS &&
        Boolean(parentObject.organization?.organizationsCountriesExposure.length)
      )
        return parentObject.organization?.organizationsCountriesExposure
          .filter(exposure => exposure.variant === 'Operation')
          .flatMap(operation => mapToChartDatapoint(parentObject, operation.country) ?? [])

      if (
        selectedLocationType === LocationTypes.SUB_SUPPLIERS &&
        Boolean(parentObject.organization?.organizationsCountriesExposure.length)
      )
        return parentObject.organization?.organizationsCountriesExposure
          .filter(exposure => exposure.variant === 'Subsupplier')
          .flatMap(subSupplier => mapToChartDatapoint(parentObject, subSupplier.country) ?? [])

      return []
    })
  }, [riskScreening, selectedLocationType, mapToChartDatapoint])

  const risksPerCountry: Array<RiskPerCountrySeriesData> = useMemo(
    () =>
      countries.map(country => {
        const riskValue = country.risks.find(r => r.riskType.id === selectedRiskId)

        const localisedCountryName = getLocalisedCountryName(country.countryCode)

        return {
          name: country.countryCode.toUpperCase(),
          value: riskValue?.value,
          countryName: localisedCountryName,
          riskStatus: riskValue?.status,
        }
      }),
    [selectedRiskId, countries, getLocalisedCountryName],
  )

  // Ads a placeholder item for countries that do not have a countryCode, such as Kosovo or Somaliland
  const noCountryCodeValueGeoJson = '-99'
  risksPerCountry.push({
    name: noCountryCodeValueGeoJson,
    value: undefined,
    countryName: '',
    riskStatus: undefined,
    emphasis: {
      show: false,
    },
    itemStyle: { color: '#feffff' },
  })

  const options: EChartsOption = useMemo(() => {
    return {
      geo: {
        nameProperty: 'iso_a2',
        map: 'worldMap',
        label: {
          show: false,
          color: '#feffff',
          fontSize: 10,
          formatter: (x: { name: string }) => {
            return risksPerCountry.find(rc => rc.name === x.name)?.countryName ?? ''
          },
        },
        emphasis: {
          label: {
            show: true,
            color: palette.text.primary,
            fontSize: 10,
          },
          itemStyle: {
            areaColor: palette.grey[200],
            borderColor: 'rgba(255, 255, 255, 0)',
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        roam: true,
        zoom: 1,
        scaleLimit: {
          min: 1,
          max: 18,
        },
        itemStyle: {
          borderColor: palette.common.white,
          borderWidth: 1,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0)',
        },
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: (formattingItem: TopLevelFormatterParams) => {
          const item = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
          const { countryName, riskStatus } = risksPerCountry.find(rc => rc.name === item.name) ?? {}
          if (!countryName) return ''
          return `${countryName} - ${formatMessage({ id: `schemas.risk.${riskStatus ?? 'notApplicable'}` })}`
        },
      },
      visualMap: {
        type: 'piecewise',
        min: 0,
        max: 100,
        pieces: getMapPieces(palette, activeRisk),
        inverse: true,
        text: [
          formatMessage({ id: 'dashboard.riskByCountryMapLow' }),
          formatMessage({ id: 'dashboard.riskByCountryMapHigh' }),
        ],
        textStyle: {
          color: palette.text.primary,
        },
        calculable: true,
        seriesIndex: 0,
      },
      series: [
        {
          name: 'risk1',
          type: 'map',
          map: 'worldMap',
          geoIndex: 0,
          emphasis: {
            label: {
              show: true,
            },
          },
          select: {
            disabled: true,
          },
          data: risksPerCountry,
        },
        {
          name: 'providers',
          type: 'effectScatter',
          animation: false,
          data: providersChartData,
          coordinateSystem: 'geo',
          showEffectOn: 'render',
          rippleEffect: {
            scale: 0,
          },
          emphasis: {
            focus: 'none',
            show: true,
            itemStyle: {
              color: palette.common.white,
              borderColor: palette.brandDecorative.darkPeach,
            },
          },
          itemStyle: {
            color: palette.common.black,
            borderColor: palette.common.white,
          },

          symbolSize: 8,
          tooltip: {
            enterable: true,
            show: true,
            formatter: '{b}',
          },
          zlevel: 1,
        },
      ],
    }
  }, [providersChartData, risksPerCountry, palette, formatMessage, activeRisk])

  const events = useMemo(
    () => ({
      click: (params: { dataIndex: number; seriesName: string; data: { countryName: string } }) => {
        if (params.seriesName === 'providers') {
          setCurrentProvider(
            riskScreening.find(x => x.parentObject.id === providersChartData[params.dataIndex].id)?.parentObject,
          )
          setCurrentCountry(risksPerCountry.find(c => c.countryName === params.data.countryName))
        } else {
          setCurrentProvider(undefined)
          setCurrentCountry(undefined)
          setExpanded(false)
        }
      },
    }),
    [setCurrentProvider, riskScreening, providersChartData, risksPerCountry],
  )

  if (!worldMap) return null

  echarts.registerMap('worldMap', worldMap)

  return (
    <Box position="relative" flexGrow={1}>
      <ReactEChartsCore option={options} echarts={echarts} style={{ width: '100%', height }} onEvents={events} />
      {currentProvider && (
        <Box
          position="absolute"
          top={1}
          right={1}
          bgcolor="common.white"
          p={2}
          borderRadius={4}
          boxShadow={wfShadows[5]}
          width={400}
          zIndex={2}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Stack flexGrow={1}>
              <CompanyCell disableCell company={currentProvider} drawer />
              {selectedLocationType !== LocationTypes.HQ && (
                <Typography variant="caption" color="textSecondary" mt={1}>{`${formatMessage({
                  id: 'dashboard.sourcing.countryRiskMap.hqLocation',
                })} ${currentProvider?.country?.name || formatMessage({ id: 'general.unknown' })}`}</Typography>
              )}
            </Stack>

            <IconButton
              onClick={() => {
                setCurrentCountry(undefined)
                setCurrentProvider(undefined)
                setExpanded(false)
              }}
            >
              <CloseIcon fontSize="small" color="primary" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography variant="body1">{currentCountry?.countryName ?? ''}</Typography>
              <SimpleRiskChipCell riskStatus={currentCountry?.riskStatus} disableCell />
            </Box>
            {selectedLocationType !== LocationTypes.HQ && (
              <Stack>
                <Collapse in={expanded}>
                  <Stack spacing={2} mt={2}>
                    <Divider />
                    {selectedLocationType === LocationTypes.OWNED_OPERATIONS &&
                      currentProvider.organization?.organizationsCountriesExposure
                        ?.filter(countryExposure => countryExposure.variant === 'Operation')
                        ?.map(country => {
                          const countryRiskStatus = risksPerCountry.find(
                            c => c.name.toUpperCase() === country.country.countryCode.toUpperCase(),
                          )?.riskStatus
                          return (
                            <Box
                              key={country.country.id}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body1" mr={2}>
                                {country.country.name}
                              </Typography>
                              <SimpleRiskChipCell riskStatus={countryRiskStatus} disableCell />
                            </Box>
                          )
                        })}
                    {selectedLocationType === LocationTypes.SUB_SUPPLIERS &&
                      currentProvider.organization?.organizationsCountriesExposure
                        ?.filter(countryExposure => countryExposure.variant === 'Subsupplier')
                        ?.map(country => {
                          const countryRiskStatus = risksPerCountry.find(
                            c => c.name.toUpperCase() === country.country.countryCode.toUpperCase(),
                          )?.riskStatus
                          return (
                            <Box
                              key={country.country.id}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body1" mr={2}>
                                {country.country.name}
                              </Typography>
                              <SimpleRiskChipCell riskStatus={countryRiskStatus} disableCell />
                            </Box>
                          )
                        })}
                  </Stack>
                </Collapse>
                <Button
                  onClick={() => setExpanded(prev => !prev)}
                  endIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  sx={{ mt: 2 }}
                >
                  {expanded
                    ? formatMessage({
                        id: `dashboard.sourcing.countryRiskMap.${
                          selectedLocationType === LocationTypes.SUB_SUPPLIERS
                            ? 'hideSubSuppliers'
                            : 'hideOwnedOperations'
                        }`,
                      })
                    : formatMessage({
                        id: `dashboard.sourcing.countryRiskMap.${
                          selectedLocationType === LocationTypes.SUB_SUPPLIERS
                            ? 'showSubSuppliers'
                            : 'showOwnedOperations'
                        }`,
                      })}
                </Button>
              </Stack>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CountryRiskMap
