import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GroupBySelectorContext from './GroupBySelectorContext'

interface Props {
  children: JSX.Element
}

const GroupBySelectorContextProvider = ({ children }: Props): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const groupBy = queryParams.groupBy as AnswersGroupingType

  const setGroupBy = (newValue: AnswersGroupingType) => {
    const newSearch = qs.stringify({ ...queryParams, groupBy: newValue }, { addQueryPrefix: true })
    history.push({ ...location, search: newSearch })
  }

  useEffect(() => {
    if (!groupBy) {
      setGroupBy(AnswersGroupingType.PeriodName)
    }
  }, [groupBy])

  const contextValue = useMemo(
    () => ({
      groupBy,
      setGroupBy,
    }),
    [groupBy, location.search],
  )

  return <GroupBySelectorContext.Provider value={contextValue}>{children}</GroupBySelectorContext.Provider>
}

export default GroupBySelectorContextProvider
