import { languagesWeHaveTranslationFilesFor } from '@app/src/i18n'
import { SUPPORTED_LANGUAGES } from '@app/src/wf-constants/languages'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useLanguageSelection = () => {
  const { availableLanguages = [] }: { availableLanguages: string[] } = useFlags()

  const availableLanguageOptions = SUPPORTED_LANGUAGES.filter(
    option =>
      languagesWeHaveTranslationFilesFor.includes(option.localeName) && availableLanguages.includes(option.localeName),
  )

  const hideLanguageSelector = availableLanguageOptions.length <= 1

  return {
    availableLanguageOptions,
    hideLanguageSelector,
  }
}

export default useLanguageSelection
