import { countries } from 'country-data'
import { useCallback, useMemo } from 'react'
import { useLocale } from '../context/LocaleContext'

const useLocalisedCountryName = () => {
  const { locale } = useLocale()
  const regionNames = useMemo(() => new Intl.DisplayNames([locale], { type: 'region' }), [locale])

  const getLocalisedCountryName = useCallback(
    (countryCode?: string): string => {
      if (!countryCode) return '-'
      const upperCountryCode = countryCode.toUpperCase()

      let localisedName
      try {
        localisedName = regionNames.of(upperCountryCode)
      } catch {
        localisedName = countries[upperCountryCode]?.name
      }

      return localisedName ?? upperCountryCode
    },
    [regionNames],
  )

  return { getLocalisedCountryName }
}

export default useLocalisedCountryName
