import useLocalisedCountryName from '@app/src/hooks/useLocalisedCountryName'
import { Box } from '@mui/material'
import Typography, { TypographyProps } from '@mui/material/Typography'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import ParentCellOrFragment from './ParentCellOrFragment'

type CountryCellProps = {
  countryCode?: string
  disableCell?: boolean
} & TypographyProps

const CountryCell: React.FC<CountryCellProps> = ({ countryCode, disableCell, ...props }) => {
  const { getLocalisedCountryName } = useLocalisedCountryName()
  const localisedCountryName = getLocalisedCountryName(countryCode)

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      {localisedCountryName ? (
        <Box display="flex" alignItems="center">
          <ReactCountryFlag countryCode={countryCode} svg style={{ fontSize: '1.5em' }} title={localisedCountryName} />
          <Typography ml={1} {...props}>
            {localisedCountryName}
          </Typography>
        </Box>
      ) : (
        <Typography {...props}>-</Typography>
      )}
    </ParentCellOrFragment>
  )
}

export default CountryCell
