import TextField from '@app/src/components/Ui/TextField'
import { useAccount } from '@app/src/context/AccountContext'
import useLanguageSelection from '@app/src/hooks/useLanguageSelection'
import { Autocomplete, Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface LanguageSelectorProps {
  control: Control
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ control }) => {
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { spacing } = useTheme()
  const { availableLanguageOptions, hideLanguageSelector } = useLanguageSelection()

  const user = account?.user

  if (hideLanguageSelector) {
    return null
  }

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="culture"
        defaultValue={user?.culture}
        render={({ onChange, value }) => (
          <Autocomplete
            fullWidth
            options={availableLanguageOptions}
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => option.localeName === value.localeName}
            value={availableLanguageOptions.find(option => option.localeName === value)}
            onChange={(_, newValue) => onChange(newValue ? newValue.localeName : null)}
            disableClearable
            renderOption={(props, option) => (
              <li {...props}>
                <ReactCountryFlag
                  countryCode={option.flagCode}
                  svg
                  style={{ fontSize: '2em', marginRight: spacing(2) }}
                />
                {option.label}
              </li>
            )}
            renderInput={params => {
              const selectedOption = availableLanguageOptions.find(option => option.localeName === value)
              return (
                <TextField
                  {...params}
                  hoveringLabel
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <ReactCountryFlag
                        countryCode={selectedOption?.flagCode}
                        svg
                        style={{ fontSize: '2em', marginRight: spacing(1), marginLeft: spacing(1) }}
                      />
                    ),
                  }}
                  label={formatMessage({ id: 'general.language' })}
                />
              )
            }}
          />
        )}
      />
    </Grid>
  )
}

export default LanguageSelector
