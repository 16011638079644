import { SpendClassification } from '@app/src/wf-constants'
import { useTheme } from '@mui/material'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

export const useSpendConfigsUtils = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const getSpendSeriesColor = useCallback((spendClassification: SpendClassification) => {
    // Todo: Adds colors to theme once they're settled on by design team
    switch (spendClassification) {
      case SpendClassification.Low:
        return '#6BBFCC'

      case SpendClassification.Medium:
        return '#F6AD63'

      case SpendClassification.High:
        return '#F6918D'

      case SpendClassification.NotSet:
      default:
        return palette.grey[300]
    }
  }, [])

  const formatGroupName = useCallback((group: string) => {
    return group === SpendClassification.NotSet
      ? formatMessage({ id: 'statistics.dataInsights.spend.notSetLabel' })
      : formatMessage({ id: 'statistics.dataInsights.spend.intervalLabel' }, { spendClassification: group })
  }, [])

  return { getSpendSeriesColor, formatGroupName }
}
