import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSavePicker } from '@app/src/components/Drawer/Views/Picker/useSavePicker'
import TextField from '@app/src/components/Ui/TextField'
import { RequestCollectionPicker } from '@app/src/types/resourceExplorer'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Alert, Box, Button, FormLabel, IconButton, Stack, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import PickerBasicFormFields, { PickerFormData } from './PickerBasicFormFields'

type DrawerViewAddPickerProps = {
  pickerToUpdate: RequestCollectionPicker
  assignPicker: (pickerId: number) => void
} & Omit<DrawerViewProps, 'title'>

const DrawerViewUpdatePicker: React.FC<DrawerViewAddPickerProps> = ({ pickerToUpdate, assignPicker, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const formMethods = useForm<PickerFormData>({
    defaultValues: { items: [{ value: '' }, { value: '' }] },
  })
  const { handleSubmit, errors, register, setValue, control, reset, getValues } = formMethods

  const { fields, append, remove } = useFieldArray<{ value: string }>({
    control,
    name: 'items',
  })
  const { savePicker, isSaving } = useSavePicker()

  useEffect(() => {
    if (!pickerToUpdate) return
    const { permittedOptions, creatorOrganizationId, ...formData } = {
      ...pickerToUpdate,
      items: pickerToUpdate.permittedOptions.map(p => ({ value: p })),
    }
    reset(formData)
  }, [pickerToUpdate])

  const onSubmit = async () => {
    // Need getValues here since the formData that comes s an argument here is undefined for disabled picker options.
    // This might be fixed in newer version of our libraries though
    const formData = getValues()
    const submitData: RequestCollectionPicker = {
      ...formData,
      id: pickerToUpdate.id,
      permittedOptions: formData.items.map(p => p.value),
    }
    await savePicker(submitData, pickerId => {
      assignPicker(pickerId)
      reset()
      closeDrawer()
    })
  }

  const addPickerOption = () => {
    append({ value: '' })
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'templateBuilder.editChosenPicker' })}
      buttons={[
        {
          onClick: handleSubmit(onSubmit),
          variant: 'contained',
          label: formatMessage({ id: 'general.update' }),
          loading: isSaving,
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} mt={2} spacing={3} sx={{ overflowX: 'clip' }} data-testid="update-picker-fields">
        <PickerBasicFormFields formMethods={formMethods} />
        <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.nameOfTheOptions' })}</FormLabel>

        <Alert variant="filled" severity="info">
          {formatMessage({ id: 'templateBuilder.pickerOptionInfo' })}
        </Alert>

        {fields.map((field, index) => {
          const name = `items[${index}].value`
          const disableEditing = fields.length <= 1 || pickerToUpdate?.permittedOptions.length > index
          return (
            <Tooltip
              title={disableEditing ? formatMessage({ id: 'templateBuilder.cannotChangeExistingOptions' }) : ''}
              key={field.id}
            >
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextField
                    fullWidth
                    defaultValue={field.value}
                    label={formatMessage({ id: 'templateBuilder.optionName' }, { number: index + 1 })}
                    name={name}
                    inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
                    required
                    error={Boolean(errors?.items?.[index]?.value)}
                    helperText={errors?.items?.[index]?.value?.message}
                    onClear={(): void => setValue(name, '')}
                    disabled={disableEditing}
                    data-testid="update-picker-drawer-input"
                  />

                  <IconButton onClick={() => remove(index)} disabled={disableEditing}>
                    <DeleteIcon color={disableEditing ? 'disabled' : 'primary'} fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
            </Tooltip>
          )
        })}

        <Button onClick={() => addPickerOption()}>{formatMessage({ id: 'templateBuilder.addAnotherOption' })}</Button>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewUpdatePicker
