import useOrganizationCurrency from '@app/src/hooks/organizationCurrency'
import { typography } from '@app/src/theme/typography'
import { Spend } from '@app/src/types/organizations'
import { toCurrencyString } from '@app/src/utils/currencyUtils'
import { SpendClassification } from '@app/src/wf-constants'
import { Chip, ChipProps, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

export type ProviderSpendClassificationCellProps = {
  spends?: Spend[]
  disableCell?: boolean
} & ChipProps

export const spendClassificationColors: { [key: string]: string } = {
  [SpendClassification.Low]: 'info.dark',
  [SpendClassification.Medium]: 'warning.dark',
  [SpendClassification.High]: 'error.dark',
}

const ProviderSpendClassificationCell: React.FC<ProviderSpendClassificationCellProps> = ({
  spends,
  disableCell,
  ...props
}) => {
  const { organizationCurrency } = useOrganizationCurrency()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()

  const preparedSpends = spends?.length
    ? spends?.map(spend => ({
        amount: toCurrencyString(spend.amount, organizationCurrency),
        spendClassification: spend.spendClassification ?? SpendClassification.NotSet,
        notSet: false,
        tooltipTitle: `${spend.periodName}: ${toCurrencyString(spend.amount, organizationCurrency)}`,
        label: formatMessage(
          { id: 'statistics.dataInsights.spend.intervalLabel' },
          { spendClassification: spend.spendClassification },
        ),
      }))
    : [
        {
          spendClassification: SpendClassification.NotSet,
          notSet: true,
          tooltipTitle: undefined,
          label: formatMessage({ id: 'statistics.dataInsights.spend.notSetLabel' }),
        },
      ]

  return (
    <ParentCellOrFragment>
      {preparedSpends.map(spend => (
        <Tooltip
          key={spend.spendClassification}
          arrow
          placement="right"
          title={spend.tooltipTitle && <Typography variant="body2">{spend.tooltipTitle}</Typography>}
        >
          <Chip
            size="small"
            variant="outlined"
            sx={{
              borderColor: spendClassificationColors[spend.spendClassification] ?? palette.grey[300],
              marginRight: 0.5,
              marginBottom: 0.5,
              '& .MuiChip-label': {
                color: spend.notSet ? palette.grey[400] : undefined,
                fontWeight: spend.notSet ? typography.body1?.fontWeight : undefined,
              },
            }}
            label={spend.label}
            {...props}
          />
        </Tooltip>
      ))}
    </ParentCellOrFragment>
  )
}

export default ProviderSpendClassificationCell
