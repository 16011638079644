import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { SpendClassification } from '@app/src/wf-constants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { createContext, useContext } from 'react'
import { SummaryChartDataTypes } from './Charts/PeriodChartsConfigs/usePeriodSummaryChartConfigs'

export type ModalStatistics = { id: number; title: string; resultForGroups: { group: string }[] }
export type UserSelection = {
  answer?: string[]
  summaryType?: SummaryChartDataTypes[]
  groupBy?: AnswersGroupingType
  groupName?: string
  periodNames?: string[]
  spendClassifications?: SpendClassification[]
}

export type DataInsightsModalContextProps = {
  openDataInsightsModal: (statistics: ModalStatistics, userSelection?: UserSelection) => void
  statistics?: ModalStatistics
  setStatistics: React.Dispatch<React.SetStateAction<ModalStatistics | undefined>>
  userSelection?: UserSelection
  setUserSelection: React.Dispatch<React.SetStateAction<UserSelection | undefined>>
  periodNames?: string[] | undefined
  formattedPeriods?: string
  spendClassifications?: SpendClassification[] | undefined
  formattedSpends?: string
  echartsRefs: React.MutableRefObject<ReactEChartsCore | null>[]
  setDataInsightsRefForIndex(ref: React.MutableRefObject<ReactEChartsCore | null>, index: number): void
  clearEchartsRefOverIndex(index: number): void
}

export const DataInsightsModalContext = createContext<DataInsightsModalContextProps>({
  openDataInsightsModal: () => undefined,
  setStatistics: () => undefined,
  setUserSelection: () => undefined,
  echartsRefs: [],
  setDataInsightsRefForIndex: () => undefined,
  clearEchartsRefOverIndex: () => undefined,
})

export const useDataInsightsModal = (): DataInsightsModalContextProps => {
  return useContext(DataInsightsModalContext)
}
