import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { RequestCollectionPicker } from '@app/src/types/resourceExplorer'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

export const useSavePicker = () => {
  const { mutateAsync, isLoading: isSaving } = useUpdateResource<RequestCollectionPicker, RequestCollectionPicker>()

  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()

  const savePicker = async (submitData: RequestCollectionPicker, onSuccess: (pickerId: number) => void) => {
    await mutateAsync(
      {
        url: endpoints.savePicker,
        body: submitData,
      },
      {
        onSuccess: async data => {
          showSnackbar({
            message: formatMessage(
              { id: 'templateBuilder.savePickerSuccess' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, pickerName: submitData.name },
            ),
            severity: 'success',
          })

          await queryClient.invalidateQueries({
            predicate: query => {
              if (!Array.isArray(query.queryKey)) return query.queryKey === FetchKey.Picker
              return query.queryKey.includes(FetchKey.Picker)
            },
          })

          onSuccess(data.id)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return { savePicker, isSaving }
}
