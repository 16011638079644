import { useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { useSpendConfigsUtils } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/SpendChartsConfigs/useSpendConfigsUtils'
import { SelectedMetric } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { StatisticsForQuestionPerGroup } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Unit } from '@app/src/types/resourceExplorer'
import {
  getMinMaxOnYAxisNumber,
  getUnitDisplayText,
  numberToString,
  numberToStringWithUnit,
} from '@app/src/utils/statisticsUtils'
import { SpendClassification, spendClassificationOrder } from '@app/src/wf-constants'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { max } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface NumberMultiPeriodChartConfigsParams {
  statistics: StatisticsForQuestionPerGroup
  selectedChartType: ChartType
  selectedMetric: SelectedMetric
  unit: Unit
}

const calculateValueAxisNameOffsetToPreventOverlap = (
  data: {
    value: number | undefined
  }[],
  unit: Unit,
  optimalUnit: Unit | undefined,
  isHorizontalChart: boolean,
) => {
  if (isHorizontalChart) return 25
  const axisLabelLengths = data.map(v => numberToString(v.value ?? 0, unit, optimalUnit).length)
  const maxAxisLabelLength = max(axisLabelLengths) ?? 0
  return 35 + Math.max(maxAxisLabelLength - 3, 0) * 7
}

export const useNumberSpendChartConfigs = ({
  statistics,
  selectedChartType,
  selectedMetric,
  unit,
}: NumberMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const { getOptimalUnitForMultipleValues } = useDisplayValueWithUnit()
  const { getSpendSeriesColor, formatGroupName } = useSpendConfigsUtils()

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const data = statistics.resultForGroups
    .sort((a, b) => spendClassificationOrder.indexOf(b.group) - spendClassificationOrder.indexOf(a.group))
    .map(r => ({
      name: formatGroupName(r.group),
      value: selectedMetric === SelectedMetric.Average ? r.numberResult?.average : r.numberResult?.sum,
      group: r.group,
    }))

  const values = data.flatMap(d => (Number(d.value) ? Number(d.value) : []))
  const optimalUnit = getOptimalUnitForMultipleValues(values, unit)

  const nameGap = calculateValueAxisNameOffsetToPreventOverlap(data, unit, optimalUnit, isHorizontalBarChart)
  const existingOptions = [getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' }))]

  const { min, max } = getMinMaxOnYAxisNumber(values, selectedMetric, optimalUnit)

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
        formatter: periodName => formatGroupName(periodName),
      },
    }),
    [existingOptions, typography.fontFamily, typography.body2.fontSize],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      axisLabel: {
        formatter: value => numberToString(value, unit, optimalUnit),
      },
      min: min,
      max: max,
      name: getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' })),
      nameLocation: 'middle',
      nameGap: nameGap,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [unit, optimalUnit, min, max, typography.fontFamily, typography.body2.fontSize, formatMessage, nameGap],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      grid: {
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: 70,
      },
      xAxis,
      yAxis,
      series: data.map(classification => ({
        name: classification.name,
        type: 'bar',
        data: [
          {
            value: classification.value,
            group: classification.group,
          },
        ],
        label: {
          show: false,
        },
        color: getSpendSeriesColor(classification.group as SpendClassification),
      })),
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, optimalUnit, data, unit])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = { ...categoryAxis, show: false } as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis,
      yAxis,
      series: data.map(classification => ({
        name: classification.name,
        type: 'bar',
        data: [
          {
            value: classification.value,
            group: classification.group,
          },
        ],
        label: {
          show: false,
        },
        color: getSpendSeriesColor(classification.group as SpendClassification),
      })),
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, optimalUnit, data, unit])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
