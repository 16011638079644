import EmptyState from '@app/src/components/EmptyState'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { br } from '@app/src/utils/translationMarkup'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { BubbleChartOutlined } from '@mui/icons-material'
import { Grid, Skeleton, Stack } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { StatisticsForQuestionPerGroup } from '../../DataHubScene'
import { InsightsSubTabs } from './DataHubInsightsScene'
import DataHubInsightsCard from './Insights/DataHubInsightsCard'
import DataHubSummaryCard from './Summary/DataHubSummaryCard'

interface DataHubInsightsProps {
  questions: StatisticsForQuestionPerGroup[] | undefined
  isLoading: boolean
  activeTab: InsightsSubTabs
  periodNames: string[]
}

const questionFilters = [
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.questionnaireTemplateId',
].map(q => q.toLowerCase())

export const GraphSkeleton = () => (
  <Stack p={2} spacing={1}>
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rounded" height={30} width="100%" />
      <Skeleton variant="rounded" height={30} width={200} />
    </Stack>
    <Skeleton variant="rounded" height={15} width="50%" />
    <Skeleton variant="rounded" height={350} width="100%" />
    <Skeleton variant="rounded" height={15} width="30%" />
  </Stack>
)

const DataHubInsights: React.FC<DataHubInsightsProps> = ({ questions, isLoading, activeTab, periodNames }) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { groupBy } = useGroupBySelector()

  const { trackEvent } = useAmplitude()

  const anyQuestionFilterSet = useMemo(
    () => userFilters.some(filter => questionFilters.includes(filter.name.toLowerCase())),
    [userFilters],
  )

  useEffect(() => {
    if (anyQuestionFilterSet && !isLoading && questions) {
      trackEvent({
        name: AmplitudeTrackingEvents.Analyze.DataHub.Insights.ViewedInsights,
        eventProps: {
          subPageViewed: activeTab,
          groupBy,
          userFilters: userFilters.map(f => f.name),
        },
      })
    }
  }, [activeTab, anyQuestionFilterSet, isLoading, questions, trackEvent, userFilters])

  if (!anyQuestionFilterSet)
    return (
      <EmptyState
        title={formatMessage({ id: 'statistics.dataInsights.emptyStateTitle' }, { br })}
        description={formatMessage({ id: 'statistics.dataInsights.emptyStateDescription' })}
        iconComponent={BubbleChartOutlined}
      />
    )

  if (isLoading || !questions)
    return (
      <Grid container spacing={2} sx={{ paddingRight: 4 }}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item md={6} key={index}>
            <GraphSkeleton />
          </Grid>
        ))}
      </Grid>
    )

  if (!isLoading && !questions.length) {
    return (
      <EmptyState
        iconComponent={BubbleChartOutlined}
        title={formatMessage({ id: 'schemas.dataHubOverTime.emptyFilterState.title' })}
        description={formatMessage({ id: 'statistics.noResults' })}
      />
    )
  }

  return (
    <Grid container spacing={2} sx={{ pr: 4, pb: 2 }}>
      {questions
        .sort((a, b) => a.id - b.id)
        .map((q, index) => {
          if (activeTab === InsightsSubTabs.INSIGHTS) {
            return (
              <Grid key={q.id} item xs={12} md={6}>
                <DataHubInsightsCard statistics={q} index={index} periodNames={periodNames} />
              </Grid>
            )
          }
          if (activeTab === InsightsSubTabs.SUMMARY) {
            return (
              <Grid key={q.id} item xs={12} md={6}>
                <DataHubSummaryCard statistics={q} index={index} periodNames={periodNames} />
              </Grid>
            )
          }
          return null
        })}
    </Grid>
  )
}

export default DataHubInsights
