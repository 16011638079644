import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import PickerBasicFormFields, { PickerFormData } from '@app/src/components/Drawer/Views/Picker/PickerBasicFormFields'
import { useSavePicker } from '@app/src/components/Drawer/Views/Picker/useSavePicker'
import TextField from '@app/src/components/Ui/TextField'
import { RequestCollectionPicker } from '@app/src/types/resourceExplorer'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Alert, Box, Button, FormLabel, IconButton, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type DrawerViewAddPickerProps = {
  assignPicker: (pickerId: number) => void
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddPicker: React.FC<DrawerViewAddPickerProps> = ({ assignPicker, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const formMethods = useForm<PickerFormData>({
    defaultValues: { items: [{ value: '' }, { value: '' }] },
  })
  const { handleSubmit, errors, register, setValue, control, reset, getValues } = formMethods
  const { fields, append, remove } = useFieldArray<{ value: string }>({
    control,
    name: 'items',
  })
  const { savePicker, isSaving } = useSavePicker()

  const onSubmit = async () => {
    const formData = getValues()
    const submitData: RequestCollectionPicker = {
      ...formData,
      permittedOptions: formData.items.map(p => p.value),
    }
    await savePicker(submitData, pickerId => {
      assignPicker(pickerId)
      reset()
      closeDrawer()
    })
  }

  const addPickerOption = () => {
    append({ value: '' })
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'templateBuilder.createAPicker' })}
      buttons={[
        {
          onClick: handleSubmit(onSubmit),
          variant: 'contained',
          label: formatMessage({ id: 'templateBuilder.createPicker' }),
          loading: isSaving,
          disabled: isSaving,
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} mt={2} spacing={3} sx={{ overflowX: 'clip' }} data-testid="create-picker-fields">
        <PickerBasicFormFields formMethods={formMethods} />

        <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.nameOfTheOptions' })}</FormLabel>

        <Alert variant="filled" severity="info">
          {formatMessage({ id: 'templateBuilder.pickerOptionInfo' })}
        </Alert>

        {fields.map((field, index) => {
          const name = `items[${index}].value`
          const disableDeletion = fields.length <= 1
          return (
            <Stack direction="row" alignItems="center" key={field.id} spacing={1}>
              <TextField
                fullWidth
                defaultValue={field.value}
                label={formatMessage({ id: 'templateBuilder.optionName' }, { number: index + 1 })}
                name={name}
                inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
                required
                error={Boolean(errors?.items?.[index]?.value)}
                helperText={errors?.items?.[index]?.value?.message}
                onClear={(): void => setValue(name, '')}
                data-testid="add-picker-drawer-input"
              />

              <Tooltip title={disableDeletion && formatMessage({ id: 'templateBuilder.mustHaveAtLeastOneOption' })}>
                <Box>
                  <IconButton onClick={() => remove(index)} disabled={disableDeletion}>
                    <DeleteIcon color={disableDeletion ? 'disabled' : 'primary'} fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          )
        })}

        <Button onClick={addPickerOption} variant="text">
          {formatMessage({ id: 'templateBuilder.addAnotherOption' })}
        </Button>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddPicker
