import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { LanguageInfo } from '@app/src/pages/Questionnaire/ReportContext'
import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const ONE_HOUR = 60 * 60 * 1000

interface TemplateLanguageSelectorProps {
  defaultValue?: string
}

const TemplateLanguageSelector: React.FC<TemplateLanguageSelectorProps> = ({ defaultValue }) => {
  const { formatMessage } = useIntl()
  const { control } = useFormContext()
  const { data, isLoading, isError } = useFetchResource<LanguageInfo[]>({
    endpoint: endpoints.availableLanguageTranslation,
    key: FetchKey.Languages,
    options: {
      staleTime: ONE_HOUR,
    },
    shouldShowErrorNotification: false,
  })

  if (isError) return null //To gracefully handle if translation service is down

  return (
    <Box pb={4}>
      <Typography variant="h6" mb={1}>
        {formatMessage({ id: 'general.language' })}
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={1}>
        {formatMessage({ id: 'templateBuilder.languageSelectorGuidance' })}
      </Typography>
      {isLoading ? (
        <Skeleton width="100%" height={61} />
      ) : (
        <Select<string>
          fullWidth
          size="small"
          control={control}
          options={data?.map(l => ({ label: l.name, value: l.code })) ?? []}
          defaultValue={defaultValue ?? 'en'}
          isLoading={isLoading}
          name="languageCode"
          fieldLabel={formatMessage({ id: 'general.language' })}
        />
      )}
    </Box>
  )
}

export default TemplateLanguageSelector
