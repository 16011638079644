import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import InquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import {
  AnswersGroupingType,
  removeQuestionFilters,
  transformFilterNames,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Spend } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { SpendClassification } from '@app/src/wf-constants'
import React, { useMemo } from 'react'
import { dataHubAllowedFilters } from '.'
import { AccessorInquiryRow } from '../../Request/AccessorInquiryRow'
import ResponseItemCompanyHeader from '../AllAnswers/ResponseItemCompanyHeader'
import ResponseItemCompanyRow from '../AllAnswers/ResponseItemCompanyRow'
import { SummaryChartDataTypes } from './Charts/PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { UserSelection, useDataInsightsModal } from './DataInsightsModalContext'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'

const filterMatchingOrNotSetSpends = (
  periodNames: string[] | undefined,
  spendClassifications: SpendClassification[] | undefined,
  spends: Spend[] | undefined,
): { isValid: boolean; spends: Spend[] } => {
  if (!spends?.length) return { isValid: true, spends: [] }
  if (periodNames?.length) {
    spends = spends?.filter(spend => periodNames.includes(spend.periodName))
  }

  if (!spends?.length) return { isValid: true, spends: [] }

  const validSpends = spends.filter(spend =>
    spendClassifications?.includes(spend.spendClassification ?? SpendClassification.NotSet),
  )
  return { isValid: Boolean(validSpends.length), spends: validSpends }
}

const getResponseItemFilters = (
  questionId?: number,
  selection?: UserSelection,
  cleanFilters?: FilterGroup[],
  periodNames?: string[],
  spendClassifications?: string[],
  providerIdsWithSpend?: number[],
  isGroupBySpend?: boolean,
): FilterGroup[] => {
  const hasFilterForCompaniesAnswered = selection?.summaryType?.includes(SummaryChartDataTypes.CompaniesAnswered)
  const hasFilterForWeDontHaveThis = selection?.summaryType?.includes(
    SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
  )

  return [
    ...(cleanFilters ?? []),
    RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
    ...insertIf(!(hasFilterForWeDontHaveThis && hasFilterForCompaniesAnswered), {
      name: 'cannotAnswer',
      filters: [
        {
          value: hasFilterForWeDontHaveThis ?? false,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(questionId), {
      name: 'requestItem.questionId',
      filters: [
        {
          value: questionId,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(selection?.answer?.length) && !selection?.summaryType, {
      name: 'answer',
      filters: [
        {
          value: selection?.answer,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(Boolean(periodNames?.length), {
      name: 'response.request.periodName',
      filters: [
        {
          value: periodNames,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(Boolean(isGroupBySpend && !spendClassifications?.includes(SpendClassification.NotSet)), {
      name: 'response.request.subscriptions.target.spends.spendClassification',
      filters: [
        {
          value: spendClassifications,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(Boolean(isGroupBySpend && periodNames && !spendClassifications?.includes(SpendClassification.NotSet)), {
      name: 'response.request.subscriptions.target.spends.periodName',
      filters: [
        {
          value: periodNames,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(
      Boolean(
        isGroupBySpend &&
          providerIdsWithSpend?.length &&
          spendClassifications?.length === 1 &&
          spendClassifications[0] === SpendClassification.NotSet,
      ),
      {
        name: 'response.request.subscriptions.target.id',
        filters: [
          {
            value: providerIdsWithSpend,
            operator: Operators.NotIn,
          },
        ],
      },
    ),
  ]
}
const DataInsightsModalTable: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { statistics, userSelection, periodNames, spendClassifications } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)

  const cleanFilters = useMemo(() => removeQuestionFilters(userFilters), [userFilters])
  const renamedFiltersForInquiries = useMemo(() => transformFilterNames(cleanFilters), [cleanFilters])

  const isCompaniesNotAnswered =
    userSelection?.summaryType?.includes(SummaryChartDataTypes.CompaniesNotAnswered) ?? false

  const { groupBy } = useGroupBySelector()
  const isGroupBySpend = groupBy === AnswersGroupingType.SpendClassification
  const isGroupBySpendAndSpendIncludesNotSet =
    isGroupBySpend && spendClassifications?.includes(SpendClassification.NotSet)

  // If spendClassifications includes "NotSet" we need to fetch providers with spend
  // using a facet call, to filter out providers that don't have spend in the collection call.

  const {
    facets: [providersWithSpendFacet],
    isFetched: providersWithSpendFacetIsFetched,
  } = useFetchFacets({
    key: FetchKey.Provider,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id', isEnum: true }],
    filter: [
      {
        name: 'spends.spendClassification',
        filters: [
          {
            value:
              spendClassifications?.length === 1
                ? [SpendClassification.Low, SpendClassification.Medium, SpendClassification.High]
                : spendClassifications?.filter(x => x !== SpendClassification.NotSet),
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'spends.periodName',
        filters: [
          {
            value: periodNames,
            operator: Operators.In,
          },
        ],
      },
    ],
    options: {
      staleTime: 60000,
      enabled: isGroupBySpendAndSpendIncludesNotSet,
    },
  })

  const providerIdsWithSpend = useMemo(
    () =>
      isGroupBySpendAndSpendIncludesNotSet ? providersWithSpendFacet?.map(facet => Number(facet.value)) : undefined,
    [isGroupBySpendAndSpendIncludesNotSet, providersWithSpendFacet],
  )

  const responseItemFilters = useMemo(
    () =>
      getResponseItemFilters(
        statistics?.id,
        userSelection,
        cleanFilters,
        periodNames,
        spendClassifications,
        providerIdsWithSpend,
        isGroupBySpend,
      ),
    [statistics, userSelection, cleanFilters, periodNames, spendClassifications, providerIdsWithSpend, isGroupBySpend],
  )

  const responseItemsPayload = {
    filter: responseItemFilters,
    sort: sorting,
    include: [
      'requestItem',
      'response.request',
      'verifications',
      'creatorOrganization',
      'response.request.creatorOrganization',
      'response.request.responderOrganization',
      'response.request.subscriptions.target',
      'response.request.subscriptions.target.country.name',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.section',
      'files',
      'flagRule.suggestedRiskGuidance',
      'response.request.target.organization',
      isGroupBySpend && 'response.request.subscriptions.target.spends',
    ].filter(Boolean) as string[],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const inquiriesPayload = useMemo(
    () => ({
      include: [
        'creatorOrganization',
        'request.responses',
        'request.responses.verifications',
        'template',
        'template.image',
        'provider.organization',
        isGroupBySpend && 'provider.spends',
      ].filter(Boolean) as string[],
      sort: sorting,
      filter: [
        ...renamedFiltersForInquiries,
        {
          name: 'status',
          filters: [
            {
              value: InquiryStatus.Requested,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'template.sections.questions.id',
          filters: [
            {
              operator: Operators.EqualTo,
              value: statistics?.id,
            },
          ],
        },
        {
          name: 'periodName',
          filters: [
            {
              operator: Operators.In,
              value: periodNames,
            },
          ],
        },
        ...insertIf(Boolean(isGroupBySpend && !spendClassifications?.includes(SpendClassification.NotSet)), {
          name: 'provider.spends.spendClassification',
          filters: [
            {
              value: spendClassifications,
              operator: Operators.In,
            },
          ],
        }),
        ...insertIf(
          Boolean(isGroupBySpend && periodNames && !spendClassifications?.includes(SpendClassification.NotSet)),
          {
            name: 'provider.spends.periodName',
            filters: [
              {
                value: periodNames,
                operator: Operators.In,
              },
            ],
          },
        ),

        ...insertIf(Boolean(isGroupBySpend && providerIdsWithSpend?.length), {
          name: 'provider.id',
          filters: [
            {
              value: providerIdsWithSpend,
              operator: Operators.NotIn,
            },
          ],
        }),
      ],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    }),
    [
      sorting,
      renamedFiltersForInquiries,
      statistics?.id,
      periodNames,
      isGroupBySpend,
      spendClassifications,
      providerIdsWithSpend,
      page,
      pageSize,
    ],
  )

  const {
    items: responseItems,
    count: responseItemsCount,
    isFetched: isResponseItemsFetched,
    isError: isResponseItemsError,
  } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.Answer,
    endpoint: endpoints.responseItemsCollection,
    payload: responseItemsPayload,
    options: {
      staleTime: 60000,
      enabled:
        Boolean(statistics) &&
        !isCompaniesNotAnswered &&
        (!isGroupBySpendAndSpendIncludesNotSet || providersWithSpendFacetIsFetched),
    },
  })

  const {
    items: inquiries,
    count: inquiriesCount,
    isLoading: isInquiriesFetched,
    isError: isInquiriesError,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Inquiry,
    endpoint: endpoints.inquiriesCollection,
    payload: inquiriesPayload,
    options: {
      staleTime: 60000,
      enabled:
        Boolean(statistics) &&
        isCompaniesNotAnswered &&
        (!isGroupBySpendAndSpendIncludesNotSet || providersWithSpendFacetIsFetched),
    },
  })

  // When spendClassifications includes "NotSet" we need to perform additional filtering in the frontend because
  // the backend doesn't support filtering by "NotSet" since there is no spend to filter on.

  const responseItemsWithFilteredSpend = useMemo(() => {
    if (isCompaniesNotAnswered) return []

    return responseItems.filter(item => {
      const { isValid, spends } = filterMatchingOrNotSetSpends(
        periodNames,
        spendClassifications,
        item.response.request.targetAliasObject.spends,
      )
      if (!isValid) return false // Filter out response items that don't have matching spends

      item.response.request.targetAliasObject.spends = spends // Assign spends to include only matching spends
      return true
    })
  }, [isCompaniesNotAnswered, responseItems, isGroupBySpend, spendClassifications, periodNames])

  const inquiriesWithFilteredSpend = useMemo(() => {
    if (!isCompaniesNotAnswered) return []

    return inquiries.filter(inquiry => {
      const { isValid, spends } = filterMatchingOrNotSetSpends(
        periodNames,
        spendClassifications,
        inquiry.provider.spends,
      )
      if (!isValid) return false // Filter out inquiries that don't have matching spends

      inquiry.provider.spends = spends // Assign spends to include only matching spends
      return true
    })
  }, [isCompaniesNotAnswered, inquiries, isGroupBySpend, spendClassifications, periodNames])

  if (isCompaniesNotAnswered) {
    return (
      <>
        <Table<Inquiry>
          RowComponent={({ row }) => <AccessorInquiryRow row={row} showProviderSpend={isGroupBySpend} />}
          HeaderComponent={() => (
            <InquiryHeader toggleSorting={toggleSorting} activeSorting={sorting} showProviderSpend={isGroupBySpend} />
          )}
          data={inquiriesWithFilteredSpend}
          isLoading={!isInquiriesFetched}
          count={inquiriesCount}
          isError={isInquiriesError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </>
    )
  }

  return (
    <>
      <Table<ResponseItem>
        RowComponent={props => (
          <ResponseItemCompanyRow {...props} disableRowSelection showProviderSpend={isGroupBySpend} />
        )}
        HeaderComponent={() => (
          <ResponseItemCompanyHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            disableRowSelection
            showProviderSpend={isGroupBySpend}
          />
        )}
        data={responseItemsWithFilteredSpend}
        isLoading={!isResponseItemsFetched}
        count={responseItemsCount}
        isError={isResponseItemsError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </>
  )
}

export default DataInsightsModalTable
