import { useLocale } from '@app/src/context/LocaleContext'
import { AutoAssignTranslationState } from '@app/src/pages/Questionnaire/Provider/Report/ReportHeader'
import { LanguageInfo, useReport } from '@app/src/pages/Questionnaire/ReportContext'
import ReportTranslation from '@app/src/pages/Questionnaire/ReportTranslation'
import { SUPPORTED_LANGUAGES } from '@app/src/wf-constants/languages'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'

const getBaseLanguageFromLocale = (locale?: string) => locale?.split('-')[0].toUpperCase()

interface ProviderReportTranslationProps {
  autoAssignTranslationState: AutoAssignTranslationState
  setAutoAssignTranslationState: React.Dispatch<React.SetStateAction<AutoAssignTranslationState>>
  availableLanguagesForReport?: LanguageInfo[]
  isLoading: boolean
  isError: boolean
}

export const getAzureLanguageCorrespondingToLocale = (
  platformLocale: string,
  availableLanguagesForReport: LanguageInfo[],
) => {
  const azureLanguageCode = SUPPORTED_LANGUAGES.find(l => l.localeName === platformLocale)?.azureTranslateLanguageCode
  return availableLanguagesForReport.find(l => l.code.toUpperCase() === azureLanguageCode?.toUpperCase())
}

const ProviderReportTranslation: React.FC<ProviderReportTranslationProps> = ({
  autoAssignTranslationState,
  setAutoAssignTranslationState,
  availableLanguagesForReport,
  isLoading,
  isError,
}) => {
  const { availableLanguages: availableLanguagesForPlatform = [] }: { availableLanguages: string[] } = useFlags()
  const { locale: platformLocale } = useLocale()
  const { isRequestLoading, setReportLocale, locale: reportLocale, request } = useReport()
  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const otherLanguagesNotTurnedOnYet = !availableLanguagesForPlatform || availableLanguagesForPlatform.length <= 1
    if (otherLanguagesNotTurnedOnYet) return
    if (autoAssignTranslationState !== AutoAssignTranslationState.NoAutoAssignedLanguage) return
    if (!availableLanguagesForReport?.length) return
    if (!request?.template?.languageCode) return
    if (reportLocale) return

    const platformBaseLanguage = getBaseLanguageFromLocale(platformLocale)
    const questionnaireBaseLanguage = getBaseLanguageFromLocale(request.template.languageCode)
    if (platformBaseLanguage === questionnaireBaseLanguage) return
    const languageToSwitchTo = getAzureLanguageCorrespondingToLocale(platformLocale, availableLanguagesForReport)
    if (!languageToSwitchTo) return

    setReportLocale(languageToSwitchTo)
    setAutoAssignTranslationState(AutoAssignTranslationState.LanguageAutoAssigned)
  }, [
    autoAssignTranslationState,
    platformLocale,
    reportLocale,
    availableLanguagesForPlatform?.length,
    availableLanguagesForReport?.length,
    request?.template.languageCode,
  ])

  return (
    <ReportTranslation
      availableLanguages={availableLanguagesForReport}
      setReportLocale={locale => {
        setReportLocale(locale)

        setAutoAssignTranslationState(currentValue =>
          [
            AutoAssignTranslationState.LanguageAutoAssigned,
            AutoAssignTranslationState.LanguageAutoAssignedButReverted,
          ].includes(currentValue)
            ? AutoAssignTranslationState.LanguageAutoAssignedButChangedToSomethingElse
            : currentValue,
        )
      }}
      reportLocale={reportLocale}
      isLoading={isLoading}
      isError={isError}
      isRequestLoading={isRequestLoading}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

export default ProviderReportTranslation
