import LocaleContext, { LocaleContextProps } from '@app/src/context/LocaleContext'
import React, { ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
}

const LocaleContextProvider = ({ children }: Props): JSX.Element => {
  const [locale, setLocale] = useState<LocaleContextProps['locale']>(
    () => localStorage.getItem('cached_locale') || 'en-US',
  )
  useEffect(() => {
    if (localStorage.getItem('cached_locale') !== locale) {
      localStorage.setItem('cached_locale', locale)
    }
  }, [locale])

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>
}

export default LocaleContextProvider
