import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo } from 'react'
import { AnswersGroupingType } from '../../../../Filters/StatisticsConstants'
import { ResultForGroup, StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import { useDataInsightsModal } from '../DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiGroupChart'
import { useTextAndFileMultiPeriodChartConfigs } from './PeriodChartsConfigs/useTextAndFileMultiPeriodChartConfigs'
import { useTextAndFileSpendChartConfigs } from './SpendChartsConfigs/useTextAndFileSpendChartConfigs'

interface TextAndFileMultiGroupChartProps {
  statistics: StatisticsForQuestionPerGroup
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  legendTitle: string
  selectedChartType: ChartType
}

export const TextAndFileMultiGroupChart: React.FC<TextAndFileMultiGroupChartProps> = ({
  statistics,
  height,
  echartsRef,
  legendTitle,
  selectedChartType,
}) => {
  const { groupBy } = useGroupBySelector()
  const resultsForGroup: ResultForGroup[] = statistics.resultForGroups
  const periodOptions = useTextAndFileMultiPeriodChartConfigs({ resultsForGroup, legendTitle, selectedChartType })
  const spendOptions = useTextAndFileSpendChartConfigs({ resultsForGroup, legendTitle, selectedChartType })
  const { openDataInsightsModal } = useDataInsightsModal()

  const getChartOptions = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendOptions

      case AnswersGroupingType.PeriodName:
      default:
        return periodOptions
    }
  }, [groupBy, periodOptions, spendOptions])

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          groupName: data.group,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  return (
    <ReactEChartsCore
      onEvents={events}
      option={getChartOptions}
      echarts={echarts}
      style={{ minWidth: '100%', height }}
      ref={echartsRef}
    />
  )
}
