import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AnswersGroupingType } from '../../../../Filters/StatisticsConstants'
import { OptionResultItemMultiPeriod, ResultForGroup, StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import { useDataInsightsModal } from '../DataInsightsModalContext'
import { useOptionMultiPeriodChartConfigs } from './PeriodChartsConfigs/useOptionMultiPeriodChartConfigs'
import { SummaryChartDataTypes } from './PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { useOptionSpendChartConfigs } from './SpendChartsConfigs/useOptionSpendChartConfigs'

interface OptionBarProps {
  statistics: StatisticsForQuestionPerGroup
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
}

export interface EchartClickItemData {
  name: string
  value: number
  group: string
  summaryType?: SummaryChartDataTypes
  answersSelected: string[]
}

interface OptionsForPeriod {
  group: string
  options: OptionResultItemMultiPeriod[]
  notIncludedItems: string[]
}

const getOptionsForPeriod = (
  resultForGroup: ResultForGroup,
  selectedChartType: ChartType,
  otherText: string,
): OptionsForPeriod => {
  const optionResult = resultForGroup.optionStatisticsViews
  const numberOfItemsInView = 6
  const optionStatisticsView = optionResult?.find(op => op.maxItemCount === numberOfItemsInView) ?? optionResult?.[0]
  const itemsInView = optionStatisticsView?.items ?? []

  const mappedItems = itemsInView.map(r => (r.isOther ? { ...r, name: otherText } : r))

  const notIncludedItems = optionStatisticsView?.valuesInOther ?? []

  return {
    group: resultForGroup.group,
    options: mappedItems,
    notIncludedItems,
  }
}

export const OptionMultiGroupChart: React.FC<OptionBarProps> = ({
  statistics,
  height,
  echartsRef,
  selectedChartType,
}) => {
  const { openDataInsightsModal } = useDataInsightsModal()
  const { formatMessage } = useIntl()
  const { groupBy } = useGroupBySelector()

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const resultsForGroups = statistics.resultForGroups
  const optionItemResult = useMemo(
    () =>
      resultsForGroups?.map(r => getOptionsForPeriod(r, selectedChartType, formatMessage({ id: 'general.other' }))) ??
      [],
    [formatMessage, resultsForGroups, selectedChartType],
  )
  const events = useMemo(() => {
    return {
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          answer: data.answersSelected,
          groupName: data.group,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }
  }, [openDataInsightsModal, statistics])

  const periodOptions = useOptionMultiPeriodChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
  })

  const spendOptions = useOptionSpendChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
  })

  const getChartOptions = useMemo(() => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendOptions

      case AnswersGroupingType.PeriodName:
      default:
        return periodOptions
    }
  }, [groupBy, periodOptions, spendOptions])

  return (
    <>
      <ReactEChartsCore
        onEvents={events}
        option={getChartOptions}
        echarts={echarts}
        style={{ minWidth: '100%', height }}
        ref={echartsRef}
      />
    </>
  )
}
