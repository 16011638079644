import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import AssessmentInsightsChartsDialog from '@app/src/pages/Assessments/AssessmentInsight/AssessmentInsightsChartsDialog'
import useAssessmentInsightsQuestionChartOptions, {
  AssessmentInsightsQuestionChartDataPoint,
} from '@app/src/pages/Assessments/useAssessmentInsightsQuestionChartOptions'
import ChartTypeSelector, {
  ChartType,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ChartCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/ChartCardMenu'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentTemplate, Question, QuestionAnswerClassificationRule } from '@app/src/types/resourceExplorer'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

interface AssessmentInsightsQuestionCardProps {
  assessmentTemplate: AssessmentTemplate
  question: Question
  period: string
  providerIds?: number[]
  linkedQuestionnaireTemplateIds?: number[]
}

const AssessmentInsightsQuestionCard: React.FC<AssessmentInsightsQuestionCardProps> = ({
  assessmentTemplate,
  question,
  period,
  providerIds,
  linkedQuestionnaireTemplateIds,
}) => {
  const { formatMessage } = useIntl()
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Vertical)
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const [isDialogOpen, openDialog, closeDialog] = useDialogState()
  const [dialogData, setDialogData] = useState<{
    classificationRule: QuestionAnswerClassificationRule | undefined
    classificationRuleName: string | undefined
  }>()

  const questionDisplayText = useMemo(() => {
    if (question?.kpiName) return question.kpiName
    if (question?.questionText) {
      return question.questionText.length > 200 ? `${question.questionText.slice(0, 197)}...` : question.questionText
    }
    return ''
  }, [question?.kpiName, question?.questionText])

  const exportFilename = useMemo(() => questionDisplayText.replace('&', 'and'), [questionDisplayText])

  const responseItemsFilters = useMemo(
    () => [
      {
        name: 'response.isLatestSubmitted',
        filters: [{ operator: Operators.EqualTo, value: true }],
      },
      {
        name: 'response.request.periodName',
        filters: [{ operator: Operators.EqualTo, value: period }],
      },
      {
        name: 'requestItem.questionId',
        filters: [{ operator: Operators.EqualTo, value: question?.id }],
      },
      {
        name: 'response.request.subscriptions.target.id',
        filters: [{ operator: Operators.In, value: providerIds }],
      },
      {
        name: 'response.request.questionnaireTemplateId',
        filters: [{ operator: Operators.In, value: linkedQuestionnaireTemplateIds }],
      },
    ],
    [question, period, providerIds, linkedQuestionnaireTemplateIds],
  )

  const {
    facets: [answerClassificationResultsFacets = []],
    isFetched: isAnswerClassificationResultsFacetsFetched,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, 'questionAnswerClassificationResults'],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: 'questionAnswerClassificationResults.questionAnswerClassificationRuleId', isEnum: true }],
    filter: responseItemsFilters,
    options: {
      enabled: Boolean(question && period && providerIds?.length && linkedQuestionnaireTemplateIds?.length),
      staleTime: 60000,
    },
  })

  const options = useAssessmentInsightsQuestionChartOptions(
    answerClassificationResultsFacets,
    question?.questionAnswerClassificationRules,
    selectedChartType,
    eChartsRef,
  )

  const onCloseDialog = () => {
    closeDialog()
    setTimeout(() => {
      setDialogData({
        classificationRule: undefined,
        classificationRuleName: undefined,
      })
    }, 250) // Wait for dialog close animation to finish
  }

  const events = useMemo(
    () => ({
      click: ({ data }: { data: AssessmentInsightsQuestionChartDataPoint }) => {
        if (!data.value) return

        setDialogData({
          classificationRule: data.classificationRule,
          classificationRuleName: data.classificationRuleName,
        })
        openDialog()
      },
    }),
    [],
  )

  if (!isAnswerClassificationResultsFacetsFetched) {
    return (
      <Box p={3}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Box>
              <Typography variant="subtitle1">{questionDisplayText}</Typography>
              <Typography color="text.secondary">{period}</Typography>
            </Box>
            <Skeleton variant="rounded" height={35} width={210} />
          </Stack>
          <Skeleton variant="rounded" height={353} width="100%" />
          <Skeleton variant="rounded" height={15} width={300} />
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <Box bgcolor="common.white" p={3} sx={{ borderRadius: 1, height: '100%' }}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box>
            <Typography variant="subtitle1">{questionDisplayText}</Typography>
            <Typography color="text.secondary">{period}</Typography>
          </Box>
          <Stack direction="row" alignItems="start" spacing={1}>
            <ChartTypeSelector
              selectedChartType={selectedChartType}
              setSelectedChartType={setSelectedChartType}
              allowedChartTypes={[ChartType.Horizontal, ChartType.Vertical]}
            />
            <ChartCardMenu
              echartsRef={eChartsRef}
              title={formatMessage(
                { id: 'assessments.overviewGraph.chartImageExportFilename' },
                { assessmentName: exportFilename, period: period },
              )}
              useTitleAsIs
            />
          </Stack>
        </Stack>
        <Stack height={384}>
          <ReactEChartsCore
            option={options}
            onEvents={events}
            echarts={echarts}
            style={{ minWidth: '100%', flexGrow: 1 }}
            ref={eChartsRef}
          />
        </Stack>
      </Box>
      <AssessmentInsightsChartsDialog
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        responseItemsFilters={responseItemsFilters}
        assessmentTemplate={assessmentTemplate}
        question={question}
        period={period}
        classificationRule={dialogData?.classificationRule}
        classificationRuleName={dialogData?.classificationRuleName}
      />
    </>
  )
}

export default AssessmentInsightsQuestionCard
