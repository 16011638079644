import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell, { CompanyCellProps } from '@app/src/components/Table/Cells/CompanyCell'
import ProviderSpendClassificationCell from '@app/src/components/Table/Cells/ProviderSpendClassificationCell'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import ResponseItemRow, { ResponseItemRowProps } from './ResponseItemRow'

interface ResponseItemCompanyRowProps extends ResponseItemRowProps, Pick<CompanyCellProps, 'drawer'> {
  selectedRowsIds?: Array<number>
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, responseItem: ResponseItem) => void
  disableRowSelection?: boolean
  showProviderSpend?: boolean
}

export const ResponseItemCompanyRow = ({
  row: responseItem,
  drawer = true,
  showAnswerClassificationResults,
  selectedRowsIds,
  onCheckboxChange,
  disableRowSelection,
  showProviderSpend,
}: ResponseItemCompanyRowProps): JSX.Element => {
  const isChecked = Boolean(selectedRowsIds?.find(requestId => requestId === responseItem.id))

  return (
    <TableRow selected={isChecked}>
      {!disableRowSelection && <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange?.(e, responseItem)} />}
      <CompanyCell
        company={{
          ...responseItem.response.request.targetAliasObject,
          organization: responseItem.response.request.targetObject,
        }}
        drawer={drawer}
      />
      {showProviderSpend && (
        <ProviderSpendClassificationCell spends={responseItem.response?.request?.targetAliasObject?.spends} />
      )}
      <ResponseItemRow
        row={responseItem}
        disableTableRow
        showAnswerClassificationResults={showAnswerClassificationResults}
      />
    </TableRow>
  )
}

export default ResponseItemCompanyRow
