import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo } from 'react'
import { useDataInsightsModal } from '../DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiGroupChart'
import { usePeriodSummaryChartConfigs } from './PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { useSpendSummaryChartConfigs } from './SpendChartsConfigs/useSpendSummaryChartConfigs'

export interface StatisticsSummary {
  id: number
  title: string
  resultForGroups: StatisticsSummaryPerGroup[]
}

export interface StatisticsSummaryPerGroup {
  group: string
  companiesAnswered: number
  companiesAnsweredDontHaveThis: number
  companiesNotAnswered: number
}

interface OptionBarProps {
  statistics: StatisticsSummary
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
  groupBy: AnswersGroupingType
  periodNames: string[]
}

const DataHubSummaryChart: React.FC<OptionBarProps> = ({ statistics, echartsRef, selectedChartType, groupBy }) => {
  const { openDataInsightsModal } = useDataInsightsModal()

  const height = '384px'
  const resultsForGroup: StatisticsSummaryPerGroup[] = statistics.resultForGroups

  const periodOptions = usePeriodSummaryChartConfigs({ resultsForGroup, selectedChartType })
  const spendOptions = useSpendSummaryChartConfigs({ resultsForGroup, selectedChartType })

  const getOptions = () => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendOptions
      case AnswersGroupingType.PeriodName:
      default:
        return periodOptions
    }
  }

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          answer: [data.name],
          summaryType: data.summaryType ? [data.summaryType] : [],
          groupName: data.group,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  return (
    <ReactEChartsCore
      onEvents={events}
      option={getOptions()}
      echarts={echarts}
      style={{ minWidth: '100%', height }}
      ref={echartsRef}
    />
  )
}

export default DataHubSummaryChart
