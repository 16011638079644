import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { Box, Breadcrumbs, ButtonBase, ButtonProps, Link, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { SummaryChartDataTypes } from './Charts/PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'

interface BreadcrumbButtonProps extends ButtonProps {
  onClick?: () => void
}

export const BreadcrumbButton = ({ onClick, ...props }: BreadcrumbButtonProps): JSX.Element => (
  <Link component={ButtonBase} color="inherit" underline="none" onClick={onClick}>
    {props.children}
  </Link>
)

const DatahubBreadcrumbs: React.FC = () => {
  const { formatMessage } = useIntl()
  const {
    setUserSelection,
    userSelection,
    statistics,
    formattedPeriods,
    formattedSpends,
    periodNames,
    spendClassifications,
  } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { groupBy } = useGroupBySelector()

  const hasPeriodFilterSelected = userFilters.some(filter => filter.name === 'response.request.periodName')
  const questionTextIsLink = (userSelection?.answer || periodNames || spendClassifications) && !hasPeriodFilterSelected

  return (
    <Box mb={2}>
      <Breadcrumbs
        maxItems={2}
        aria-label="breadcrumb"
        itemsAfterCollapse={groupBy === AnswersGroupingType.PeriodName ? 1 : 2}
      >
        <Typography color="inherit">{formatMessage({ id: 'schemas.responseItem.responseItems' })}</Typography>

        {questionTextIsLink ? (
          <BreadcrumbButton
            onClick={() => {
              setUserSelection(currentSelection => {
                const isOnSummaryTab = Boolean(currentSelection?.summaryType)
                if (!isOnSummaryTab) return undefined
                return {
                  summaryType: [
                    SummaryChartDataTypes.CompaniesAnswered,
                    SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
                  ],
                }
              })
            }}
          >
            {statistics?.title}
          </BreadcrumbButton>
        ) : (
          <Typography color="inherit">{statistics?.title}</Typography>
        )}

        {userSelection?.answer && !userSelection.summaryType ? (
          <Breadcrumbs>
            <BreadcrumbButton
              onClick={() => {
                setUserSelection({ ...userSelection, answer: undefined })
              }}
            >
              {formattedPeriods}
            </BreadcrumbButton>
            <Typography color="textPrimary">
              {userSelection?.answer.length > 1 ? formatMessage({ id: 'general.other' }) : userSelection.answer[0]}
            </Typography>
          </Breadcrumbs>
        ) : (
          <Typography color="textPrimary">{formattedPeriods}</Typography>
        )}

        {groupBy === AnswersGroupingType.SpendClassification && (
          <Typography color="textPrimary">{formattedSpends}</Typography>
        )}
      </Breadcrumbs>
    </Box>
  )
}
export default DatahubBreadcrumbs
