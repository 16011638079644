import { Permissions, usePermissions } from '@app/src/auth/permissions'
import TemplateLanguageSelector from '@app/src/pages/TemplateBuilder/TemplateLanguageSelector'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { Box, Grid } from '@mui/material'
import React from 'react'
import EditRequestTags from './EditRequestTags'
import QuestionnaireType from './QuestionnaireType'

interface EditTemplateSettingsProps {
  template: QuestionnaireTemplate | undefined
}

const EditTemplateSettings: React.FC<EditTemplateSettingsProps> = ({ template }) => {
  const { hasPermission } = usePermissions()
  const isWorldfavorEmailUser = hasPermission(Permissions.WF_EMAIL_ACCESS)

  return (
    <Box my={5} pr={5} flexGrow={1}>
      <Grid container justifyContent="center">
        <Grid container item xs={6} bgcolor="common.white" p={5} borderRadius={2}>
          <Grid item xs={12}>
            <TemplateLanguageSelector defaultValue={template?.languageCode} />
          </Grid>

          {isWorldfavorEmailUser && (
            <>
              <Grid item xs={12}>
                <QuestionnaireType />
              </Grid>
              <Grid item xs={12} mt={5}>
                <EditRequestTags />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditTemplateSettings
