import Permissions, { usePermissions } from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'
import { Solutions } from '@app/src/wf-constants'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import React from 'react'
import { useIntl } from 'react-intl'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'

const ProviderMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { getWithPermission } = usePermissions()

  const solution = getWithPermission({
    [Permissions.SOURCING_USER]: Solutions.Sourcing,
    [Permissions.INVESTMENT_USER]: Solutions.Investment,
    [Permissions.FINANCE_USER]: Solutions.Finance,
  })

  if (!solution) {
    return null
  }

  const menuItems: MenuWrapperProps['menuItems'] = [
    {
      title: formatMessage({ id: 'navbar.companies' }),
      link: navbarLinks.providers,
      permission: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
    },
    { title: formatMessage({ id: 'navbar.contacts' }), link: navbarLinks.contacts },
  ]

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: `navbar.${solution}` })}
    />
  )
}

export default ProviderMenu
