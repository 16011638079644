import { Provider } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { Add, Edit } from '@mui/icons-material'
import { Box, Button, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'

import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { FinalRiskRating } from '@app/src/types/categories'
import { useFlags } from 'launchdarkly-react-client-sdk'
import BaseAssessmentOverview from './BaseAssessmentOverview'
import ProductOverview from './ProductOverview'
import RequestsOverview from './RequestsOverview'
import RiskStatusOverview from './RiskStatusOverview'

type CompanyOverviewProps = {
  provider: Provider
}

const CompanyOverview: React.FC<CompanyOverviewProps> = ({ provider }) => {
  const { path } = useRouteMatch()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { productMapping } = useFlags()
  const { hasPermission } = usePermissions()

  const isResourcePage = path === paths.resourceExplorer

  if (isResourcePage) {
    return null
  }

  return (
    <Box>
      {hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS) && <BaseAssessmentOverview providerId={provider.id} />}
      <RequestsOverview providerId={provider.id} />
      {productMapping && <ProductOverview providerId={provider.id} />}
      <RiskStatusOverview providerId={provider.id} />

      <ListItem disablePadding>
        <ListItemButton
          onClick={() =>
            openDrawer(
              <DrawerViewRiskAssessment
                providerIds={[provider.id]}
                subTitle={getOrganizationDisplayName(provider)}
                finalRiskRating={provider.finalRiskRating ?? FinalRiskRating.NotSelected}
              />,
              { showAboveDialogs: true },
            )
          }
        >
          <Box width="100%">
            <ListItemText primary={formatMessage({ id: 'schemas.providerRisk.yourRiskAssessment' })} />
            <FinalRiskRatingCell value={provider.finalRiskRating} disableCell />
          </Box>
          {provider.finalRiskRating === FinalRiskRating.NotSelected ? (
            <Button size="medium" variant="text" startIcon={<Add />}>
              {formatMessage({ id: 'general.add' })}
            </Button>
          ) : (
            <Button size="medium" variant="text" startIcon={<Edit />}>
              {formatMessage({ id: 'general.edit' })}
            </Button>
          )}
        </ListItemButton>
      </ListItem>
    </Box>
  )
}

export default CompanyOverview
