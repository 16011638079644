import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { SpendClassification, spendClassificationOrder } from '@app/src/wf-constants'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { QuestionMonitoring, ResultForGroup } from '../../../../DataHubScene'
import { useSpendConfigsUtils } from './useSpendConfigsUtils'

interface TextAndFileMultiPeriodChartConfigsParams {
  resultsForGroup: ResultForGroup[]
  legendTitle: string
  selectedChartType: ChartType
}

const getMinMaxYAxis = (optionItemResult: { questionMonitoring: QuestionMonitoring }[]) => {
  const haveAnsweredValues = optionItemResult.map(res => res.questionMonitoring.companiesHaveAnswered.number)
  return getMinMaxOnYAxisCountCharts(haveAnsweredValues)
}

export const useTextAndFileSpendChartConfigs = ({
  resultsForGroup,
  legendTitle,
  selectedChartType,
}: TextAndFileMultiPeriodChartConfigsParams) => {
  const { typography } = useTheme()
  const { formatMessage } = useIntl()
  const { getSpendSeriesColor, formatGroupName } = useSpendConfigsUtils()

  const orderedResultsForGroup = resultsForGroup.sort(
    (a, b) => spendClassificationOrder.indexOf(b.group) - spendClassificationOrder.indexOf(a.group),
  )

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const existingOptions = [legendTitle]
  const { min, max } = getMinMaxYAxis(orderedResultsForGroup)

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      minInterval: 1,
      nameGap: 30,
      min: min,
      max: max,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        show: false,
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: 70,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: orderedResultsForGroup.map((r, i) => ({
        name: formatGroupName(r.group),
        type: 'bar',
        barCategoryGap: '50%',
        data: [
          {
            name: legendTitle,
            value: r.questionMonitoring.companiesHaveAnswered.number,
            group: r.group,
          },
        ],
        color: getSpendSeriesColor(r.group as SpendClassification),
      })),
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, legendTitle])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        show: false,
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      series: orderedResultsForGroup.map((r, i) => ({
        name: formatGroupName(r.group),
        type: 'bar',
        barCategoryGap: '50%',
        data: [
          {
            name: legendTitle,
            value: r.questionMonitoring.companiesHaveAnswered.number,
            group: r.group,
          },
        ],
        color: getSpendSeriesColor(r.group as SpendClassification),
      })),
    }
  }, [categoryAxis, valueAxis, typography.fontFamily, typography.body2.fontSize, legendTitle])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
