import { useLocale } from '@app/src/context/LocaleContext'
import { getDateWithoutTimeFromString } from '@app/src/utils/helpersTs'
import { SUPPORTED_LANGUAGES } from '@app/src/wf-constants/languages'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React from 'react'

export type DatePickerProps = Omit<MUIDatePickerProps<Date>, 'onChange' | 'value'> & {
  onChange: (date: string | null) => void
  value: string | null
}

type FormatDateOptions = {
  date: Date | null
  withoutTime?: boolean
}

const mapToValue = (value: string | Date | null) => {
  if (!value) return null
  if (value instanceof Date) return value
  return getDateWithoutTimeFromString(value)
}

const DatePicker: React.FC<DatePickerProps> = ({ value, label, onChange, ...props }) => {
  const { locale } = useLocale()
  const dateFnsLocale = SUPPORTED_LANGUAGES.find(l => l.localeName === locale)?.dateFnsLocale
  const handleChange: MUIDatePickerProps<Date>['onChange'] = value => {
    if (!isNaN(value?.getTime() ?? NaN)) {
      onChange(formatDate({ date: value, withoutTime: true }))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
      <MUIDatePicker {...props} value={mapToValue(value)} onChange={handleChange} label={label} format="yyyy-MM-dd" />
    </LocalizationProvider>
  )
}

export const formatDate = ({ date, withoutTime }: FormatDateOptions) => {
  if (!date) return null

  let month = `${date.getMonth() + 1}`
  let day = `${date.getDate()}`
  const year = `${date.getFullYear()}`

  if (month?.length < 2) month = `0${month}`
  if (day?.length < 2) day = `0${day}`

  return withoutTime ? `${year}-${month}-${day}` : `${year}-${month}-${day} 00:00:00`
}

export default DatePicker
