import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import ChartCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/ChartCardMenu'
import { Box, ButtonBase, Paper, Stack, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { StatisticsForQuestionPerGroup } from '../../DataHubScene'

interface DataHubInsightsCardProps {
  children: React.ReactNode
  statistics: StatisticsForQuestionPerGroup
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
  periodNames: string[]
}

const DataHubCard: React.FC<DataHubInsightsCardProps> = ({
  children,
  statistics,
  eChartsRef,
  onClickTitle,
  chartSelector,
  periodNames,
}) => {
  const { formatPeriodName } = usePeriodName()
  const { formatMessage } = useIntl()
  const { groupBy } = useGroupBySelector()

  return (
    <Paper elevation={0} sx={{ height: '100%', padding: 3, outline: 0, borderRadius: 1 }}>
      <Stack justifyContent="space-between" height="100%">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="start" pr={2}>
            <Box>
              <Box component={ButtonBase} onClick={onClickTitle} textAlign="left">
                <TruncatedText text={statistics.title} line={1} variant="subtitle1" />
              </Box>
              {groupBy === 'periodName' && statistics.resultForGroups.length === 1 && (
                <Typography color="text.secondary">{formatPeriodName(statistics.resultForGroups[0].group)}</Typography>
              )}
              {groupBy === 'spendClassification' && (
                <Typography color="text.secondary">
                  {formatMessage({ id: 'statistics.dataInsights.spend.cardSubtitle' }, { period: periodNames[0] })}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="start" gap={1}>
            {chartSelector}
            <ChartCardMenu echartsRef={eChartsRef} title={statistics.title} />
          </Box>
        </Box>
        {children}
      </Stack>
    </Paper>
  )
}

export default DataHubCard
