import { NotificationSeverity } from '@app/src/wf-constants'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import endpoints from '@app/src/api/endpoints'
import { useUpdateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Alert, Button, Grid, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

type PasswordFieldType = 'text' | 'password'

const ChangePasswordForm = (): JSX.Element => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()

  const { account } = useAccount()
  const classes = useStyles()
  const { handleSubmit, formState, setError, clearErrors, watch, register, setValue } = useForm()
  const { formatMessage } = useIntl()
  const [currentPasswordType, setCurrentPasswordType] = useState<PasswordFieldType>('password')
  const [newPasswordType, setNewPasswordType] = useState<PasswordFieldType>('password')
  const [repeatPasswordType, setRepeatPasswordType] = useState<PasswordFieldType>('password')
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const { mutate, isLoading: isSubmitting } = useUpdateResource<PasswordPutPayload>()
  const newPassword = watch('newPassword')

  const user = account?.user

  const toggleTextFieldType = (type: PasswordFieldType): PasswordFieldType =>
    type === 'password' ? 'text' : 'password'

  const getPasswordButtonLabel = (type: PasswordFieldType): string =>
    type === 'password' ? 'general.show' : 'general.hide'

  interface PasswordPutPayload {
    id?: number
    currentPassword: string
    newPassword: string
    repeatNewPassword: string
  }

  const handlePasswordSubmit = async (values: PasswordPutPayload): Promise<void> => {
    values.id = user?.id
    mutate(
      { url: endpoints.password, body: values },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'userSettings.passwordChanged' }),
            severity: NotificationSeverity.success,
          })
          window.location.reload()
        },
        onError: error => {
          if (error.isValidationError) {
            const validation = error.body?.validation
            setErrorMessage(validation?.items?.[0]?.message)
            error.setFormValidationErrors(setError)
          } else {
            showErrorNotification({ requestError: error })
          }
        },
      },
    )
  }

  if (!user) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} height={60} />
        ))}
      </>
    )
  }

  const errors = formState.errors

  return (
    <form onSubmit={handleSubmit(handlePasswordSubmit)} noValidate>
      <Grid container spacing={3} alignItems="center">
        {Object.keys(formState.errors)?.length > 0 && (
          <Grid item xs={12}>
            <Alert elevation={1} variant="filled" severity="error">
              {errorMessage || formatMessage({ id: 'form.validation.error' })}
            </Alert>
          </Grid>
        )}
        <Grid item xs={10}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.currentPassword' })}
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name="currentPassword"
            label={formatMessage({ id: 'schemas.user.currentPassword' })}
            error={Boolean(errors?.currentPassword)}
            type={currentPasswordType}
            helperText={errors?.currentPassword?.message}
            onClear={(): void => setValue('currentPassword', '')}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            onClick={(): void => setCurrentPasswordType(toggleTextFieldType(currentPasswordType))}
          >
            {formatMessage({ id: getPasswordButtonLabel(currentPasswordType) })}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.newPassword' })}
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name="newPassword"
            label={formatMessage({ id: 'schemas.user.newPassword' })}
            error={Boolean(errors?.newPassword)}
            type={newPasswordType}
            helperText={errors?.newPassword?.message}
            onClear={(): void => setValue('newPassword', '')}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="outlined" onClick={(): void => setNewPasswordType(toggleTextFieldType(newPasswordType))}>
            {formatMessage({ id: getPasswordButtonLabel(newPasswordType) })}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            hoveringLabel
            placeholder={formatMessage({ id: 'textFieldPlaceholders.repeatNewPassword' })}
            inputRef={register({
              required: formatMessage({ id: 'form.validation.required' }),
              validate: (value: string) =>
                value === newPassword || formatMessage({ id: 'notifications.errorPasswordMatch' }),
            })}
            required
            name="repeatNewPassword"
            label={formatMessage({ id: 'schemas.user.repeatNewPassword' })}
            error={Boolean(errors?.repeatNewPassword)}
            type={repeatPasswordType}
            helperText={errors?.repeatNewPassword?.message}
            onClear={(): void => setValue('repeatNewPassword', '')}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            onClick={(): void => setRepeatPasswordType(toggleTextFieldType(repeatPasswordType))}
          >
            {formatMessage({ id: getPasswordButtonLabel(repeatPasswordType) })}
          </Button>
        </Grid>
        <Grid item xs={10} className={classes.submitButton}>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={(): void => errors.Password && clearErrors()} // TODO Discuss with BE to have 'general' errors
            loading={isSubmitting}
          >
            {formatMessage({ id: 'general.save' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default ChangePasswordForm
