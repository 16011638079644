import {
  AnswersGroupingType,
  getProviderIdsFromFilter as getProviderOrganizationIdsFromFilter,
  removeQuestionFilters,
  transformFilterNames,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { uniq } from 'lodash'
import endpoints from '../api/endpoints'
import { FetchKey, useFetchCount, useFetchFacets } from '../api/fetchHooks'
import { FacetItem } from '../pages/ResourceCollection'
import {
  StatisticsSummary,
  StatisticsSummaryPerGroup,
} from '../pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/DataHubSummaryChart'
import { StatisticsForQuestionPerGroup } from '../pages/ResourceCollection/Collections/DataHubScene'
import { FilterGroup, Operators } from '../pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '../types/resourceExplorer'
import { comparePeriods } from '../utils/getOrderedPeriods'
import { insertIf } from '../utils/helpersTs'
import { SpendClassification } from '../wf-constants'
import { getPeriodsToShowInStatisticsView } from './usePeriodName'

const getTotalPeriods = (
  statistics: StatisticsForQuestionPerGroup,
  unansweredInquiriesByPeriodFacets: FacetItem[],
): string[] => {
  const totalPeriods = uniq([
    ...statistics.resultForGroups.map(r => r.group),
    ...unansweredInquiriesByPeriodFacets.filter(r => r?.value).map(r => r.value.toString()),
  ]).sort(comparePeriods)
  return getPeriodsToShowInStatisticsView(totalPeriods)
}
const toPeriodStatisticsSummaryResult = (
  statistics: StatisticsForQuestionPerGroup,
  unansweredInquiriesByPeriodFacets: FacetItem[],
): StatisticsSummary => {
  const totalPeriods = getTotalPeriods(statistics, unansweredInquiriesByPeriodFacets)

  const statisticsSummaryResult: StatisticsSummaryPerGroup[] = totalPeriods.map(period => {
    const resultGroup = statistics.resultForGroups.find(r => r?.group === period)
    const unansweredQuestionCount = unansweredInquiriesByPeriodFacets.find(p => p?.value === period)?.count ?? 0

    return {
      group: period,
      companiesAnswered: resultGroup?.questionMonitoring?.companiesHaveAnswered?.number ?? 0,
      companiesAnsweredDontHaveThis: resultGroup?.questionMonitoring?.companiesHaveNotAnswered?.number ?? 0,
      companiesNotAnswered: unansweredQuestionCount,
    }
  })

  return {
    id: statistics.id,
    title: statistics.title,
    resultForGroups: statisticsSummaryResult,
  }
}

const getTotalSpendClassifications = (
  statistics: StatisticsForQuestionPerGroup,
  unansweredInquiriesWithSpendsFacets: FacetItem[],
): string[] => {
  const totalSpendIntervals = uniq([
    ...statistics.resultForGroups.map(r => r.group),
    ...(unansweredInquiriesWithSpendsFacets?.filter(r => r?.value).map(r => r.value.toString()) ?? []),
  ])
  return totalSpendIntervals
}
const toSpendStatisticsSummaryResult = (
  statistics: StatisticsForQuestionPerGroup,
  unansweredInquiriesWithSpendsFacets: FacetItem[],
  unansweredInquiriesWithSpendsFacetsCount: number,
  unansweredInquiriesCount: number,
): StatisticsSummary => {
  const totalSpendIntervals = getTotalSpendClassifications(statistics, unansweredInquiriesWithSpendsFacets)

  const statisticsSummaryResult: StatisticsSummaryPerGroup[] = totalSpendIntervals.map(spendInterval => {
    const resultGroup = statistics.resultForGroups.find(r => r?.group === spendInterval)
    const unansweredQuestionCount =
      unansweredInquiriesWithSpendsFacets.find(p => p?.value === spendInterval)?.count ?? 0

    return {
      group: spendInterval,
      companiesAnswered: resultGroup?.questionMonitoring?.companiesHaveAnswered?.number ?? 0,
      companiesAnsweredDontHaveThis: resultGroup?.questionMonitoring?.companiesHaveNotAnswered?.number ?? 0,
      companiesNotAnswered:
        spendInterval === SpendClassification.NotSet
          ? unansweredInquiriesCount - unansweredInquiriesWithSpendsFacetsCount
          : unansweredQuestionCount,
    }
  })

  return {
    id: statistics.id,
    title: statistics.title,
    resultForGroups: statisticsSummaryResult,
  }
}

export const useStatisticsResultByGroup = (
  statistics: StatisticsForQuestionPerGroup,
  groupBy: AnswersGroupingType,
  userFilters: FilterGroup[],
) => {
  const cleanFilters = removeQuestionFilters(userFilters)
  const renamedFiltersForInquiries = transformFilterNames(cleanFilters)
  const providerOrganizationIds = getProviderOrganizationIdsFromFilter(userFilters)
  const periodFilterValue = userFilters.find(x => x.name === 'response.request.periodName')?.filters[0]?.value

  const facetsFilters = [
    ...renamedFiltersForInquiries,
    {
      name: 'template.sections.questions.id',
      filters: [
        {
          operator: Operators.EqualTo,
          value: statistics.id,
        },
      ],
    },
    {
      name: 'status',
      filters: [{ operator: Operators.EqualTo, value: InquiryStatus.Requested }],
    },
    ...insertIf(Boolean(providerOrganizationIds), {
      name: 'provider.organizationId',
      filters: [
        {
          operator: Operators.In,
          value: providerOrganizationIds,
        },
      ],
    }),
  ]

  const { facets: unansweredInquiriesByPeriodFacets, isLoading: isLoadingUnansweredInquiriesByPeriodFacets } =
    useFetchFacets({
      key: FetchKey.Inquiry,
      endpoint: endpoints.inquiryWithFacets,
      facetsParam: [{ name: 'periodName', isEnum: true }],
      filter: facetsFilters,
      skipNotSet: true,
      options: { staleTime: 60000, enabled: groupBy === AnswersGroupingType.PeriodName },
    })

  const { count: unansweredInquiriesCount, isLoading: isUnansweredInquiriesCountLoading } = useFetchCount({
    key: FetchKey.InquiryCount,
    endpoint: endpoints.inquiryCount,
    payload: facetsFilters,
    options: { staleTime: 60000, enabled: groupBy === AnswersGroupingType.SpendClassification },
  })

  const {
    facets: unansweredInquiriesWithSpendsFacet,
    count: unansweredInquiriesWithSpendsFacetCount,
    isLoading: isLoadingUnansweredInquiriesWithSpendsFacet,
  } = useFetchFacets({
    key: FetchKey.Inquiry,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'provider.spends.spendClassification' }],
    filter: [
      ...facetsFilters,
      ...insertIf(Boolean(periodFilterValue), {
        name: 'provider.spends.periodName',
        filters: [
          {
            operator: Operators.In,
            value: Array.isArray(periodFilterValue) ? periodFilterValue : [String(periodFilterValue)],
          },
        ],
      }),
    ],
    skipNotSet: true,
    options: { staleTime: 60000, enabled: groupBy === AnswersGroupingType.SpendClassification },
  })

  const isLoading =
    isLoadingUnansweredInquiriesByPeriodFacets ||
    isUnansweredInquiriesCountLoading ||
    isLoadingUnansweredInquiriesWithSpendsFacet

  const periodStatisticSummaryResult = toPeriodStatisticsSummaryResult(
    statistics,
    unansweredInquiriesByPeriodFacets.flat(),
  )
  const spendStatisticSummaryResult = toSpendStatisticsSummaryResult(
    statistics,
    unansweredInquiriesWithSpendsFacet.flat(),
    unansweredInquiriesWithSpendsFacetCount,
    unansweredInquiriesCount ?? 0,
  )

  return { periodStatisticSummaryResult, spendStatisticSummaryResult, isLoading }
}
