import { Stack, Typography } from '@mui/material'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { SuggestedContactReasonType } from '@app/src/types/resourceExplorer'
import { useDrawer } from '../../DrawerContext'
import DrawerView, { DrawerViewProps } from '../../DrawerView'
import DrawerViewAddProviderContactDetails from './DrawerViewAddProviderContactDetails'
import DrawerViewAddProviderContactInformation from './DrawerViewAddProviderContactInformation'

type DrawerViewAddProviderContactProps = {
  companyName: string
  providerId: number
} & Omit<DrawerViewProps, 'title'>

export type ReasonFormData = {
  reasonType: SuggestedContactReasonType | null
  reasonExtraInfo?: string
}

const DrawerViewAddProviderContactReason: React.FC<DrawerViewAddProviderContactProps> = ({
  companyName,
  providerId,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { control, errors, handleSubmit, register, watch, setValue } = useForm<ReasonFormData>({
    mode: 'onChange',
    defaultValues: {
      reasonType: null,
    },
  })

  const reasonWatcher = watch('reasonType')

  const onSubmit = (values: ReasonFormData) => {
    openDrawer(
      <DrawerViewAddProviderContactDetails companyName={companyName} reason={values} providerId={providerId} />,
      { showAboveDialogs: true },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.addProviderContact.addContact' })}
      subTitle={companyName}
      buttons={[
        {
          label: formatMessage({ id: 'general.learnMore' }),
          onClick: () => openDrawer(<DrawerViewAddProviderContactInformation />, { showAboveDialogs: true }),
        },
        {
          label: formatMessage({ id: 'general.continue' }),
          variant: 'contained',
          type: 'submit',
        },
      ]}
      stackButtons
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack p={2} spacing={3}>
        <Typography>
          {formatMessage({ id: 'schemas.addProviderContact.reasonDescription' }, { companyName })}
        </Typography>
        <Select
          name="reasonType"
          fieldLabel={formatMessage({ id: 'schemas.addProviderContact.form.reason' })}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          control={control}
          enableAutoSelect
          options={Object.keys(SuggestedContactReasonType).map(type => ({
            label: formatMessage({ id: `schemas.addProviderContact.form.reasonTypes.${type}` }),
            value: type,
          }))}
          error={errors?.reasonType?.message}
        />

        {reasonWatcher === SuggestedContactReasonType.Other && (
          <TextField
            name="reasonExtraInfo"
            label={formatMessage({ id: 'schemas.addProviderContact.form.reasonExtraInfo' })}
            placeholder={formatMessage({ id: 'schemas.addProviderContact.form.reasonExtraInfoPlaceholder' })}
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            multiline
            rows={5}
            error={Boolean(errors?.reasonExtraInfo)}
            onClear={() => setValue('reasonExtraInfo', '')}
            helperText={errors?.reasonExtraInfo?.message}
          />
        )}
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProviderContactReason
