import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { DefaultImages, Logos } from '@app/src/wf-constants'
import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import OnboardingLanguageSelector from './OnboardingLanguageSelector'

interface OnboardingFormProps {
  children: React.ReactNode
  header?: string
  preHeader?: string
  subheader?: string
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({ children, header = '', preHeader = '', subheader = '' }) => {
  return (
    <Grid container height="100%">
      <Grid item xs={12} md={6} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box py={3} px={4}>
            <Avatar
              src={Logos.WorldfavorLogoSquareFilledBlack}
              size={AvatarSize.Large}
              sx={{ backgroundColor: 'common.white' }}
            />
          </Box>
          <Box py={3} px={4}>
            <OnboardingLanguageSelector />
          </Box>
        </Box>

        <Stack flexGrow={1} alignItems="center">
          <Stack flexGrow={1} width="480px" py={2} px={4} spacing={3}>
            <Stack spacing={1}>
              {preHeader && (
                <Typography color="textSecondary" mb={1}>
                  {preHeader}
                </Typography>
              )}
              <Typography variant="h4">{header}</Typography>
              <Typography color="textSecondary">{subheader}</Typography>
            </Stack>
            <Stack flexGrow={1} spacing={2}>
              {children}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={12} md={6}>
        <Box
          sx={{
            backgroundImage: `url('${DefaultImages.SignupFlow}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          flexGrow={1}
        />
      </Grid>
    </Grid>
  )
}

export default OnboardingForm
