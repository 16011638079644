import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import usePrevious from '@app/src/hooks/previous'
import { useQueryState, useStringifyQueryFilters } from '@app/src/hooks/queryState'
import AssessmentCompareScene from '@app/src/pages/Assessments/AssessmentCompareScene'
import { useFetchAssessmentSettings } from '@app/src/pages/Assessments/useFetchAssessmentSettings'
import { DEFAULT_FILTER_NAME, FilterInterface } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { generatePath, Route, Switch, useParams } from 'react-router-dom'
import AssessmentOverviewScene from './AssessmentOverviewScene'

export enum AssessmentViews {
  Overview = 'overview',
  Compare = 'compare',
}

interface AssessmentSceneParams {
  view: AssessmentViews
}

export const assessmentOverviewAllowedFilters = [
  'assessments.id',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.percentage',
  'assessments.assessmentTemplate.name',
  'assessments.assessmentTemplate.id',
  'id',
  'name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'assessmentTemplate.id',
  'periodName',
  'provider.id',
  'provider.categoryOptions.id',
  'provider.spends.spendClassification',
  'provider.spends.periodName',
  'totalLevel',
  'percentage',
  'assessmentTemplate.name',
  'section1Level',
  'section2Level',
  'section3Level',
  'section4Level',
  'section5Level',
  'intervalType',
]

const AssessmentsScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { view } = useParams<AssessmentSceneParams>()
  const [filters, setFilters] = useQueryState<Array<FilterInterface>>(DEFAULT_FILTER_NAME, [])
  const previousFilters = usePrevious(filters)

  useEffect(() => {
    if (previousFilters?.length) setFilters(previousFilters)
  }, [view])

  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const actionTabs: TabsProps['tabs'] = [
    {
      type: AssessmentViews.Overview,
      url: stringifyQueryFilters({
        url: generatePath(paths.assessments, { view: AssessmentViews.Overview }),
        queryParams: {},
      }),
      label: formatMessage({ id: 'assessment.tabs.overview' }),
    },
    {
      type: AssessmentViews.Compare,
      url: stringifyQueryFilters({
        url: generatePath(paths.assessments, { view: AssessmentViews.Compare }),
        queryParams: {},
      }),
      label: formatMessage({ id: 'assessment.tabs.compare' }),
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  const { assessmentSettings } = useFetchAssessmentSettings()
  const assessmentTemplates =
    assessmentSettings
      ?.filter(assessmentSetting => assessmentSetting.isActivated)
      .map(assessmentSetting => assessmentSetting.assessmentTemplate) ?? []
  const assessmentTemplateIds = assessmentTemplates.map(assessmentTemplate => assessmentTemplate.id)

  const facetFilter = [
    {
      name: 'assessmentTemplateId',
      filters: [
        {
          operator: Operators.In,
          value: assessmentTemplateIds,
        },
      ],
    },
  ]

  const {
    facets: [assessmentResultPeriodNameFacet = []],
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, 'periodName'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: facetFilter,
    sort: {
      order: SortOrder.DESCENDING,
      target: 'value',
    },
    options: { enabled: Boolean(assessmentTemplateIds?.length), staleTime: 60000 },
  })
  const periodNames = assessmentResultPeriodNameFacet.map(facet => facet.value) as string[]

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.assessments, {
          view: AssessmentViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route path={generatePath(paths.assessments, { view: AssessmentViews.Overview })} exact>
          <AssessmentOverviewScene
            tabs={{ actionTabs, activeTabParam: view }}
            allowedFilters={assessmentOverviewAllowedFilters}
            activatedAssessmentTemplates={assessmentTemplates}
            assessedPeriodNames={periodNames}
          />
        </Route>
        <Route path={generatePath(paths.assessments, { view: AssessmentViews.Compare })} exact>
          <AssessmentCompareScene
            tabs={{ actionTabs, activeTabParam: view }}
            allowedFilters={assessmentOverviewAllowedFilters}
            activatedAssessmentTemplates={assessmentTemplates}
            assessedPeriodNames={periodNames}
          />
        </Route>
      </Switch>
    </>
  )
}

export default AssessmentsScene
