import { Button, ButtonGroup } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface NumberMetricSelectorProps {
  selectedMetric: SelectedMetric
  setSelectedMetric: React.Dispatch<React.SetStateAction<SelectedMetric>>
  showSum: boolean
}

export enum SelectedMetric {
  Average = 'average',
  Sum = 'sum',
}

const METRICS = [
  {
    metric: SelectedMetric.Average,
    textId: 'statistics.dataInsights.avg',
  },
  {
    metric: SelectedMetric.Sum,
    textId: 'statistics.dataInsights.sum',
  },
]

export const NumberMetricSelector: React.FC<NumberMetricSelectorProps> = ({
  selectedMetric,
  setSelectedMetric,
  showSum,
}) => {
  const { formatMessage } = useIntl()

  const selectableMetrics = showSum ? METRICS : METRICS.filter(m => m.metric !== SelectedMetric.Sum)

  return (
    <ButtonGroup sx={{ mt: 1 }}>
      {selectableMetrics.map(metricInfo => (
        <Button
          size="small"
          key={metricInfo.metric}
          variant={selectedMetric === metricInfo.metric ? 'contained' : 'outlined'}
          onClick={() => setSelectedMetric(metricInfo.metric)}
        >
          {formatMessage({ id: metricInfo.textId })}
        </Button>
      ))}
    </ButtonGroup>
  )
}
