import { Permissions, usePermissions } from '@app/src/auth/permissions'
import LinkButton from '@app/src/components/LinkButton'
import SustainabilityLibraryMenuItem from '@app/src/components/Navbar/NavbarItems/SustainabilityLibraryMenuItem'
import WorldfavorLogo from '@app/src/components/Navbar/WorldfavorLogo'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import PublicContactEnforcer from '@app/src/context/PublicContactEnforcer'
import useFeatureFlagsByAccessor from '@app/src/hooks/useFeatureFlagsByAccessor'
import { Solutions } from '@app/src/wf-constants'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, Drawer, Grid, IconButton, Toolbar, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import AccountMenu from './AccountMenu'
import NavbarItems from './NavbarItems'
import AiFileRepositoryLink from './NavbarItems/AiFileRepositoryLink'
import ConfigurationsMenuItem from './NavbarItems/ConfigurationsMenuItem'
import NotificationsMenuItem from './NavbarItems/NotificationsMenuItem'
import SustainabilityProfileMenuItem from './NavbarItems/SustainabilityProfileMenuItem'
import OrganizationMenu from './OrganizationMenu'

const useStyles = makeStyles(({ palette }) => ({
  appbar: {
    boxShadow: 'none',
    backgroundColor: palette.background.default,
    borderBottom: `1px solid ${palette.grey[100]}`,
    borderRadius: 0,
  },
  toolbar: {
    minHeight: '56px',
    justifyContent: 'space-between',
  },
  container: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
  },
  responsiveNavbarDrawer: {
    width: '320px',
    height: '100%',
    margin: 0,
    borderRadius: 0,
  },
  closeDrawerButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  responsiveNavbarItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const Navbar = (): JSX.Element => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { scope } = useAuthentication()
  const { hasPermission } = usePermissions()
  const { solution = '' } = scope
  const classes = useStyles()
  const [isResponsiveMenuOpen, setResponseMenuOpen] = useState(false)
  const { orgOrAccessorHasFeature: suggestedAnswers } = useFeatureFlagsByAccessor({
    featureToggleName: 'suggested-answers',
    enabled: hasPermission(Permissions.TRANSPARENCY_USER),
  })

  const displayableSolutions = Object.values(Solutions)

  const solutionExists = solution && displayableSolutions.includes(String(solution))
  return (
    <AppBar classes={{ root: classes.appbar }} position="sticky" color="transparent" data-testid="navbar">
      <PublicContactEnforcer />
      <Toolbar disableGutters classes={{ root: classNames(classes.container, classes.toolbar) }}>
        <Box className={classes.container}>
          {(scope.solution !== 'default' || scope.role?.toLowerCase() === 'worldfavoremployeeadmin') && (
            <>
              <Tooltip arrow title={formatMessage({ id: 'navbar.menu' })}>
                <IconButton
                  color="default"
                  aria-label="menu"
                  onClick={(): void => setResponseMenuOpen(true)}
                  size="medium"
                  sx={{ px: 2, borderRadius: 0, height: '100%', display: { xs: 'block', md: 'none' } }}
                >
                  <MenuIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Drawer open={isResponsiveMenuOpen} anchor="left" classes={{ paper: classes.responsiveNavbarDrawer }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.responsiveNavbarItems}>
                      <IconButton onClick={(): void => setResponseMenuOpen(false)} size="medium">
                        <CloseIcon fontSize="medium" />
                      </IconButton>
                    </div>
                  </Grid>
                  <NavbarItems flexDirection="column" closeMenuDrawer={() => setResponseMenuOpen(false)} />
                </Grid>
              </Drawer>
            </>
          )}

          {scope.solution !== 'default' || scope.role?.toLowerCase() === 'worldfavoremployeeadmin' ? (
            <OrganizationMenu account={account}></OrganizationMenu>
          ) : (
            <Box sx={{ ml: 2, mr: 1.5 }}>
              <LinkButton
                startIcon={<WorldfavorLogo />}
                to="/"
                className={classes.logoButton}
                disableRipple
              ></LinkButton>
            </Box>
          )}

          <Box height="100%" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <NavbarItems />
          </Box>
        </Box>

        <Box className={classes.container}>
          {account && (
            <>
              {solutionExists && solution !== Solutions.Transparency && <SustainabilityLibraryMenuItem />}
              {solutionExists && solution === Solutions.Transparency && <SustainabilityProfileMenuItem />}
              {suggestedAnswers && hasPermission(Permissions.TRANSPARENCY_USER) && <AiFileRepositoryLink />}
              {hasPermission(Permissions.ACCESS_SOLUTION_CONFIGURATION) && <ConfigurationsMenuItem />}
              {solutionExists && <NotificationsMenuItem />}
              <AccountMenu account={account} />
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
