import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { LanguageInfo, useReport } from '@app/src/pages/Questionnaire/ReportContext'
import ReportTranslation from '@app/src/pages/Questionnaire/ReportTranslation'
import React, { useState } from 'react'

const ONE_HOUR = 60 * 60 * 1000

const AccessorReportTranslation = () => {
  const { isRequestLoading, setReportLocale, locale: reportLocale } = useReport()
  const [isOpen, setOpen] = useState<boolean>(false)

  const {
    data: availableLanguagesForReport,
    isLoading,
    isError,
  } = useFetchResource<LanguageInfo[]>({
    endpoint: endpoints.availableLanguageTranslation,
    key: FetchKey.Languages,
    options: {
      staleTime: ONE_HOUR,
      enabled: isOpen,
    },
    shouldShowErrorNotification: false,
  })

  return (
    <ReportTranslation
      availableLanguages={availableLanguagesForReport}
      setReportLocale={setReportLocale}
      reportLocale={reportLocale}
      isLoading={isLoading}
      isError={isError}
      isRequestLoading={isRequestLoading}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

export default AccessorReportTranslation
