import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { LanguageInfo } from '@app/src/pages/Questionnaire/ReportContext'
import { TranslateOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ReportTranslationProps {
  availableLanguages?: LanguageInfo[]
  isRequestLoading?: boolean
  isError?: boolean
  reportLocale: LanguageInfo | null
  isLoading: boolean
  setReportLocale: (locale: LanguageInfo | null) => void
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReportTranslation: React.FC<ReportTranslationProps> = ({
  availableLanguages,
  isRequestLoading,
  isLoading,
  isError,
  setReportLocale,
  reportLocale,
  isOpen,
  setOpen,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Box minWidth={220}>
      <SimpleSelect<string>
        fullWidth
        InputProps={{
          startAdornment: <TranslateOutlined fontSize="small" color="action" sx={{ mr: 1 }} />,
        }}
        size="small"
        options={availableLanguages?.map(l => ({ label: l.name, value: l.code })) ?? []}
        disabled={isRequestLoading || isError}
        value={reportLocale ? { value: reportLocale.code, label: reportLocale.name } : null}
        loading={isLoading}
        fieldLabel={formatMessage({ id: 'general.language' })}
        onChange={opt => {
          const option = [opt].flat()?.[0]
          setReportLocale(option ? { code: option.value, name: option.label } : null)
        }}
        open={isOpen}
        setOpen={setOpen}
      />
    </Box>
  )
}
export default ReportTranslation
