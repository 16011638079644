import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import EmptyState from '@app/src/components/EmptyState'
import LoadingButton from '@app/src/components/LoadingButton'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useRiskSettingActivation from '@app/src/hooks/useRiskSettingActivation'
import AssessmentDialog from '@app/src/pages/Assessments/AssessmentDialog'
import RiskIndexIcon from '@app/src/pages/Configurations/ConfigurationsPages/RiskScreening/RiskIndexIcons/RiskIndexIcon'
import ChartTypeSelector, {
  ChartType,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ChartCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/Charts/ChartCardMenu'
import ExposedCountryRiskMap, {
  ProviderRisk,
  RiskLocationEnum,
} from '@app/src/pages/ResourceCollection/Collections/RiskScreening/ExposedCountryRiskMap'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RiskTableView } from '@app/src/types/organizations'
import {
  AssessmentTemplate,
  Country,
  OrganizationRiskSetting,
  RiskStatus,
  RiskType,
} from '@app/src/types/resourceExplorer'
import { InfoOutlined } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import AssessmentRiskIndexBarChart from './AssessmentRiskIndexBarChart'

interface AssessmentRiskIndexProps {
  assessmentTemplate: AssessmentTemplate
  riskType: RiskType
  riskScreeningItems: RiskTableView[]
  riskSetting: OrganizationRiskSetting
  userFilters: FilterGroup[]
}

const AssessmentRiskIndex: React.FC<AssessmentRiskIndexProps> = ({
  assessmentTemplate,
  riskType,
  riskScreeningItems,
  riskSetting,
  userFilters,
}) => {
  const { formatMessage } = useIntl()
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Horizontal)
  const [activeEChartsRef, setActiveEChartsRef] = useState<React.MutableRefObject<null | ReactEChartsCore>>()
  const [isRiskIndexActivated, setIsRiskIndexActivated] = useState(riskSetting.isActivated)
  const { toggleRiskActivationAsync, isSaving } = useRiskSettingActivation(riskSetting, {
    onToggled: isActivated => {
      setIsRiskIndexActivated(isActivated)
    },
  })
  const [assessmentDialogOpen, openAssessmentDialog, closeAssessmentDialog] = useDialogState()
  const { openDrawer } = useDrawer()

  const [dialogData, setDialogData] = useState<{
    riskStatus: RiskStatus | undefined
    assessmentLevel: number | undefined
    providerIds: Array<number> | undefined
    countryName: string | undefined
  }>()

  const getEChartsRef = (eChartsRef: React.MutableRefObject<null | ReactEChartsCore>) => {
    setActiveEChartsRef(eChartsRef)
  }

  const activePeriod = useMemo(() => {
    return (
      userFilters.find(x => x.name === 'periodName')?.filters[0]?.value?.toString() ??
      new Date().getFullYear().toString()
    )
  }, [userFilters])

  if (!riskType) return null

  const onCountryClick = (country: Country, riskStatuses: RiskStatus[], countryProviderRisks: ProviderRisk[]) => {
    setDialogData({
      riskStatus: riskStatuses[0],
      assessmentLevel: undefined,
      providerIds: countryProviderRisks.map(x => x.provider.id),
      countryName: country.name,
    })
    openAssessmentDialog()
  }

  const onBarChartClick = (
    riskStatus: RiskStatus | undefined,
    assessmentLevel: number | undefined,
    providerIds: Array<number> | undefined,
  ) => {
    setDialogData({
      riskStatus: riskStatus,
      assessmentLevel: assessmentLevel,
      providerIds: providerIds,
      countryName: undefined,
    })
    openAssessmentDialog()
  }

  const handleCloseDialog = () => {
    closeAssessmentDialog()
    setTimeout(() => {
      setDialogData({
        riskStatus: undefined,
        assessmentLevel: undefined,
        providerIds: undefined,
        countryName: undefined,
      })
    }, 250)
  }

  return (
    <>
      <Box height={384}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box ml={1} alignItems="center">
            <Avatar
              size={AvatarSize.Large}
              sx={({ palette }) => ({
                backgroundColor: 'white',
                border: `1px solid ${palette.secondary.main}`,
              })}
            >
              <RiskIndexIcon
                source={riskSetting.riskType.source}
                onClick={() => openDrawer(<RiskIndexInfoDrawerView riskType={riskSetting.riskType} />)}
              />
            </Avatar>
          </Box>
          <Box flexGrow={1} ml={1.5}>
            <TruncatedText text={riskType.name} line={1} variant="subtitle1" />
            <TruncatedText
              text={formatMessage({ id: 'assessments.relatedRisk.assessmentResultGroupedByIndex' })}
              line={1}
              color="text.secondary"
            />
          </Box>
          {isRiskIndexActivated && activeEChartsRef && (
            <Box display="flex" alignItems="start" gap={1}>
              <ChartTypeSelector
                selectedChartType={selectedChartType}
                setSelectedChartType={setSelectedChartType}
                allowedChartTypes={[ChartType.Horizontal, ChartType.Map]}
              />
              <ChartCardMenu
                echartsRef={activeEChartsRef}
                title={`${assessmentTemplate.name.replace('&', 'and')} results grouped by ${riskType.name} risk ${activePeriod}`}
                useTitleAsIs
              />
            </Box>
          )}
        </Box>

        {isRiskIndexActivated ? (
          <>
            {selectedChartType === ChartType.Map ? (
              <ExposedCountryRiskMap
                activeRisk={riskType}
                riskScreening={riskScreeningItems}
                selectedRiskId={riskType.id}
                riskLevelFilter={[RiskStatus.Low, RiskStatus.Medium, RiskStatus.High, RiskStatus.Extreme]}
                height={384}
                getEChartsRef={getEChartsRef}
                riskLocationsTypes={[RiskLocationEnum.Headquarter]}
                onCountryClick={onCountryClick}
              />
            ) : (
              <AssessmentRiskIndexBarChart
                riskType={riskType}
                riskScreeningItems={riskScreeningItems}
                userFilters={userFilters}
                assessmentTemplate={assessmentTemplate}
                getEChartsRef={getEChartsRef}
                onClick={onBarChartClick}
              />
            )}
            <AssessmentDialog
              isOpen={assessmentDialogOpen}
              closeModal={handleCloseDialog}
              assessmentTemplate={assessmentTemplate}
              periods={[activePeriod]}
              riskType={riskType}
              activeLevel={dialogData?.assessmentLevel}
              riskStatus={dialogData?.riskStatus}
              providerIds={dialogData?.providerIds}
              countryName={dialogData?.countryName}
            />
          </>
        ) : (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <EmptyState
              iconComponent={InfoOutlined}
              title={formatMessage({ id: 'assessments.relatedRisk.inactiveEmptyState.title' })}
              description={formatMessage({ id: 'assessments.relatedRisk.inactiveEmptyState.description' })}
            >
              <LoadingButton variant="outlined" onClick={toggleRiskActivationAsync} loading={isSaving}>
                {formatMessage({ id: 'assessments.relatedRisk.inactiveEmptyState.activate' })}
              </LoadingButton>
            </EmptyState>
          </Stack>
        )}
      </Box>
    </>
  )
}

export default AssessmentRiskIndex
