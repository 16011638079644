import { useDialogState } from '@app/src/hooks/mui-hooks'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { SpendClassification } from '@app/src/wf-constants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import DataInsightsModal from './DataInsightsModal'
import {
  DataInsightsModalContext,
  DataInsightsModalContextProps,
  ModalStatistics,
  UserSelection,
} from './DataInsightsModalContext'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { dataHubAllowedFilters } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/index'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'

const DataInsightsModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, openModal, closeModal] = useDialogState(false)
  const [userSelection, setUserSelection] = useState<UserSelection>()
  const [statistics, setStatistics] = useState<ModalStatistics>()
  const [echartsRefs, setEchartRefs] = useState<Array<React.MutableRefObject<ReactEChartsCore | null>>>([])
  const { formatPeriodName } = usePeriodName()
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { groupBy } = useGroupBySelector()

  const filteredPeriodNames = useMemo(() => {
    const periodNameFilterValue = userFilters.find(x => x.name === 'response.request.periodName')?.filters[0]
      ?.value as string[]

    const safePeriodFilterValue = periodNameFilterValue
      ? Array.isArray(periodNameFilterValue)
        ? periodNameFilterValue
        : [periodNameFilterValue]
      : undefined
    return safePeriodFilterValue?.sort((a, b) => comparePeriods(a, b))
  }, [userFilters])

  const openDataInsightsModal: DataInsightsModalContextProps['openDataInsightsModal'] = (statistics, userSelection) => {
    if (userSelection && userSelection.groupName) {
      if (groupBy === AnswersGroupingType.PeriodName) {
        userSelection.periodNames = [userSelection.groupName]
      } else if (groupBy === AnswersGroupingType.SpendClassification) {
        userSelection.spendClassifications = [userSelection.groupName] as SpendClassification[]
      }
      delete userSelection.groupName
    }

    setStatistics(statistics)
    setUserSelection(userSelection)
    if (!isOpen) openModal()
  }

  const setDataInsightsRefForIndex = (ref: React.MutableRefObject<ReactEChartsCore | null>, index: number) => {
    setEchartRefs(currentValue => {
      currentValue[index] = ref
      return currentValue
    })
  }

  const clearEchartsRefOverIndex = useMemo(
    () => (index: number) => {
      setEchartRefs(currentValues => {
        return currentValues.slice(0, index)
      })
    },
    [],
  )

  const [periodNames, formattedPeriods] = useMemo(() => {
    const names =
      userSelection?.groupName && groupBy === AnswersGroupingType.PeriodName
        ? [userSelection.groupName]
        : userSelection?.periodNames ??
          filteredPeriodNames ??
          (groupBy === AnswersGroupingType.PeriodName
            ? statistics?.resultForGroups?.map(item => item.group)
            : undefined)

    const formatted = names?.map(formatPeriodName).join(' + ') ?? ''

    return [names, formatted]
  }, [userSelection, statistics])

  const [spendClassifications, formattedSpends] = useMemo(() => {
    const spends =
      userSelection?.spendClassifications ??
      (groupBy === AnswersGroupingType.SpendClassification
        ? (statistics?.resultForGroups?.map(item => item.group) as SpendClassification[])
        : undefined)

    const formatted =
      spends
        ?.map(spend =>
          spend === SpendClassification.NotSet
            ? formatMessage({ id: 'statistics.dataInsights.spend.notSetLabel' })
            : formatMessage({ id: 'statistics.dataInsights.spend.intervalLabel' }, { spendClassification: spend }),
        )
        .join(' + ') ?? ''

    return [spends, formatted]
  }, [userSelection, statistics, formatMessage])

  return (
    <DataInsightsModalContext.Provider
      value={{
        openDataInsightsModal,
        statistics,
        setStatistics,
        userSelection,
        setUserSelection,
        periodNames,
        formattedPeriods,
        spendClassifications,
        formattedSpends,
        echartsRefs,
        setDataInsightsRefForIndex,
        clearEchartsRefOverIndex,
      }}
    >
      {children}
      {statistics && <DataInsightsModal isOpen={isOpen} closeModal={closeModal} />}
    </DataInsightsModalContext.Provider>
  )
}

export default DataInsightsModalContextProvider
