import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { palette } from '@app/src/theme/palette'
import { getMinMaxOnYAxisCountCharts } from '@app/src/utils/statisticsUtils'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { BarDataItemOption } from 'echarts/types/src/chart/bar/BarSeries'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { QuestionMonitoring, ResultForGroup } from '../../../../DataHubScene'

interface TextAndFileMultiPeriodChartConfigsParams {
  resultsForGroup: ResultForGroup[]
  legendTitle: string
  selectedChartType: ChartType
}

const getMinMaxYAxis = (optionItemResult: { questionMonitoring: QuestionMonitoring }[]) => {
  const haveAnsweredValues = optionItemResult.map(res => res.questionMonitoring.companiesHaveAnswered.number)
  return getMinMaxOnYAxisCountCharts(haveAnsweredValues)
}

const COLORS = [palette.visualization['5']]

export const useTextAndFileMultiPeriodChartConfigs = ({
  resultsForGroup,
  legendTitle,
  selectedChartType,
}: TextAndFileMultiPeriodChartConfigsParams) => {
  const { typography } = useTheme()
  const { formatMessage } = useIntl()
  const { formatPeriodName } = usePeriodName()

  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal

  const fileUploadedResult =
    resultsForGroup?.map(r => ({
      group: formatPeriodName(r.group),
      questionMonitoring: r.questionMonitoring,
    })) ?? []

  const data: BarDataItemOption[] = fileUploadedResult.map(r => ({
    group: formatPeriodName(r.group),
    value: r.questionMonitoring.companiesHaveAnswered.number,
  }))

  const existingOptions = fileUploadedResult.map(f => f.group)
  const { min, max } = getMinMaxYAxis(fileUploadedResult)

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      minInterval: 1,
      nameGap: 30,
      min: min,
      max: max,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      axisLabel: {
        interval: 0,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        show: false,
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: 70,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      color: COLORS,
      series: [
        {
          name: legendTitle,
          type: 'bar',
          label: {
            show: true,
            position: [0, -14],
            align: 'left',
            color: 'black',
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
            formatter: params => String(params.name),
          },
          data: data,
        },
      ],
    }
  }, [valueAxis, categoryAxis, typography.fontFamily, typography.body2.fontSize, legendTitle, data])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        show: false,
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis,
      yAxis,
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      color: COLORS,
      series: [
        {
          name: legendTitle,
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            align: 'center',
            color: 'black',
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
            formatter: params => String(params.value),
          },
          data: data,
        },
      ],
    }
  }, [categoryAxis, valueAxis, typography.fontFamily, typography.body2.fontSize, legendTitle, data])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
