import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Grid } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

export const GroupByFilter = ({ isDetailsTabActive }: { isDetailsTabActive?: boolean }) => {
  const { formatMessage } = useIntl()
  const { groupBy, setGroupBy } = useGroupBySelector()
  const [isOpen, setOpen] = useState(false)
  const { groupBy: showGroupBy } = useFlags()

  const GROUP_BY_OPTIONS = [
    {
      label: formatMessage({ id: 'reporting.period' }),
      value: AnswersGroupingType.PeriodName,
      count: 0,
    },
    {
      label: formatMessage({ id: 'dataHub.groupBy.spend' }),
      value: AnswersGroupingType.SpendClassification,
      count: 0,
    },
  ]

  if (!showGroupBy || isDetailsTabActive) {
    return null
  }

  return (
    <Grid item xs={2}>
      <SimpleSelect<AnswersGroupingType>
        onChange={value => {
          if (value && !Array.isArray(value)) {
            setGroupBy(value.value)
          }
        }}
        open={isOpen}
        setOpen={setOpen}
        loading={false}
        options={GROUP_BY_OPTIONS}
        fieldLabel={formatMessage({ id: 'dataHub.groupBy.name' })}
        value={GROUP_BY_OPTIONS.find(option => option.value === groupBy) || null}
        variant="outlined"
        size="small"
      />
    </Grid>
  )
}
