import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FiltersProps } from '@app/src/pages/ResourceCollection/index'
import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import useLocalisedCountryFilter from './Common/useLocalisedCountryFilter'

const ProductMappingCompaniesFilters: React.FC<FiltersProps> = ({ allowedFilters, resetPage, implicitFilters }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { renderOption, renderTags } = useLocalisedCountryFilter()

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ isFiltered, inDrawerFiltersCount, clearQueryFilter }) => (
          <>
            <Grid item md={4} sm={8}>
              <Filter name="id" operator={Operators.In}>
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    size="small"
                    implicitFilters={implicitFilters}
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'name' }],
                    }}
                    filterName={filterName}
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.organization.name' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item md={3} sm={4}>
              <Filter operator={Operators.In} name="country.id">
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.Provider, filterName],
                      endpoint: endpoints.providersWithFacets,
                      facetsParam: [{ name: 'country.iso3166Alpha2' }],
                    }}
                    renderOption={renderOption}
                    renderTags={renderTags}
                    filterName={filterName}
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item md={3}>
              <Box display="inline-block">
                <AllFiltersButton
                  inDrawerFiltersCount={inDrawerFiltersCount}
                  openDrawer={() =>
                    openDrawer(
                      <DrawerViewFilters allowedFilters={allowedFilters}>
                        <Box px={2}>
                          <FilterSection label={formatMessage({ id: 'schemas.filter.companies' })}>
                            <Filter name="id" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  implicitFilters={implicitFilters}
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: 'name' }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.organization.name' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>

                            <Filter operator={Operators.In} name="country.id">
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: 'country.iso3166Alpha2' }],
                                  }}
                                  renderOption={renderOption}
                                  renderTags={renderTags}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                            <Filter name="websiteAddress" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  implicitFilters={implicitFilters}
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: 'websiteAddress', isEnum: true }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.organization.websiteAddress' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                            <Filter name="vatNumber" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  implicitFilters={implicitFilters}
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: 'vatNumber', isEnum: true }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.organization.vatNumber' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                            <Filter name="registrationNumber" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  implicitFilters={implicitFilters}
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.providersWithFacets,
                                    facetsParam: [{ name: 'registrationNumber', isEnum: true }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.organization.registrationNumber' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                          </FilterSection>
                        </Box>
                      </DrawerViewFilters>,
                    )
                  }
                />
              </Box>

              {isFiltered && (
                <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                  {formatMessage({ id: 'filters.clearAll' })}
                </Button>
              )}
            </Grid>
          </>
        )}
      </Filters>
    </Grid>
  )
}

export default ProductMappingCompaniesFilters
