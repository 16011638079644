import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useGetFilterLabels } from '@app/src/hooks/getFilterLabels'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterValue, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Chip, ChipProps, CircularProgress } from '@mui/material'
import React from 'react'

interface FilterDisplayChipProps {
  filterName: string
  filterValue: FilterValue | undefined
  allowDelete?: boolean
  size?: ChipProps['size']
  color?: ChipProps['color']
  operator?: Operators
}

const FilterDisplayChip: React.FC<FilterDisplayChipProps> = ({
  filterName,
  filterValue,
  allowDelete,
  size = 'small',
  color,
  operator,
}) => {
  const { setQueryFilter } = useFiltersContext()

  const isActorTypeIdFilter = filterName.endsWith('actorTypeModel.id')
  const isCountryFilter = filterName.endsWith('country.id')
  const isCompanyFilter = filterName.endsWith('target.id')
  const isOrganizationFilter = filterName.endsWith('target.organizationId')
  const isOwnerFilter = filterName.endsWith('ownerUserId')
  const isQuestionIdFilter = filterName.endsWith('questionId')
  const isQuestionnaireIdFilter = filterName.endsWith('questionnaireTemplateId')
  const isLegislationIdFilter = filterName.endsWith('template.options.id')
  const isPeriodNameFilter = filterName.endsWith('periodName')
  const isVerificationStatusesFilter = filterName.endsWith('verifications.externalVerificationStatus')
  const isInternalVerificationStatusesFilter = filterName.endsWith('verifications.internalVerificationStatus')

  const {
    facets: [countryNames],
    isLoading: isCountryNamesLoading,
  } = useFetchFacets({
    key: FetchKey.CountryFacets,
    endpoint: endpoints.countriesWithFacets,
    facetsParam: [{ name: 'iso3166Alpha2' }],
    options: {
      enabled: isCountryFilter,
    },
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [companyNames],
    isLoading: isCompanyNamesLoading,
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'name' }],
    options: {
      enabled: isCompanyFilter,
    },
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [organizationNames],
    isLoading: isOrganizationNamesLoading,
  } = useFetchFacets({
    key: FetchKey.OrganizationFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'name' }],
    options: {
      enabled: isOrganizationFilter,
    },
    filter: [
      {
        name: 'organization.id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [userNames],
    isLoading: isUserNamesLoading,
  } = useFetchFacets({
    key: FetchKey.Users,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'ownerUser.name' }],
    options: {
      enabled: isOwnerFilter,
    },
    filter: [
      {
        name: 'ownerUserId',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [actorTypeNames],
    isLoading: isActorTypeNamesLoading,
  } = useFetchFacets({
    key: FetchKey.ActorTypeFacets,
    endpoint: endpoints.ActorTypesWithFacets,
    facetsParam: [{ name: 'name' }],
    options: {
      enabled: isActorTypeIdFilter,
    },
    filter: [
      {
        name: 'id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [questionNames],
    isLoading: isQuestionNamesLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, filterName],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: 'requestItem.template.questionText' }],
    options: {
      enabled: isQuestionIdFilter,
    },
    filter: [
      {
        name: 'requestItem.questionId',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [questionnaireNames],
    isLoading: isQuestionnaireNamesLoading,
  } = useFetchFacets({
    key: FetchKey.RequestFacets,
    endpoint: endpoints.requestsWithFacets,
    facetsParam: [{ name: 'template.title' }],
    options: {
      enabled: isQuestionnaireIdFilter,
    },
    filter: [
      {
        name: 'template.id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [legislationNames],
    isLoading: isLegislationNamesLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, filterName],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: 'response.request.template.sections.questions.options.name' }],
    options: {
      enabled: isLegislationIdFilter,
    },
    filter: [
      {
        name: 'response.request.template.sections.questions.options.id',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'response.request.template.sections.questions.options.category.name',
        filters: [{ value: 'Legislation', operator: Operators.EqualTo }],
      },
    ],
  })

  const {
    facets: [periodNames],
    isLoading: isPeriodNamesLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, filterName],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: 'response.request.periodName', isEnum: true }],
    options: {
      enabled: isPeriodNameFilter,
    },
    filter: [
      {
        name: 'response.request.periodName',
        filters: [
          {
            value: Array.isArray(filterValue) ? filterValue : [String(filterValue)],
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [verificationStatuses],
    isLoading: isVerificationStatusesLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, filterName],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: filterName, isEnum: true }],
    options: {
      enabled: isVerificationStatusesFilter,
    },
    filter: [
      {
        name: 'response.verifications.externalVerificationStatus',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const {
    facets: [internalVerificationStatuses],
    isLoading: isInternalVerificationStatusesLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets, filterName],
    endpoint: endpoints.responseItemsWithFacets,
    facetsParam: [{ name: filterName, isEnum: true }],
    options: {
      enabled: isInternalVerificationStatusesFilter,
    },
    filter: [
      {
        name: 'response.verifications.internalVerificationStatus',
        filters: [
          {
            value: filterValue,
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const isLoading =
    isCountryNamesLoading ||
    isCompanyNamesLoading ||
    isUserNamesLoading ||
    isActorTypeNamesLoading ||
    isOrganizationNamesLoading ||
    isQuestionNamesLoading ||
    isQuestionnaireNamesLoading ||
    isLegislationNamesLoading ||
    isPeriodNamesLoading ||
    isVerificationStatusesLoading ||
    isInternalVerificationStatusesLoading

  const { getFilterLabel } = useGetFilterLabels({
    countryNames,
    companyNames,
    userNames,
    actorTypeNames,
    organizationNames,
    questionNames,
    questionnaireNames,
    legislationNames,
    periodNames,
    verificationStatuses,
    internalVerificationStatuses,
  })

  return (
    <Chip
      label={isLoading ? <CircularProgress size={12} /> : getFilterLabel(filterName, filterValue, operator)}
      size={size}
      onDelete={allowDelete ? () => setQueryFilter(filterName, '', Operators.In) : undefined}
      sx={theme => ({
        margin: theme.spacing(0.5),
        height: 'auto',
        whiteSpace: 'wrap',
        '& .MuiChip-label': {
          whiteSpace: 'wrap',
          wordBreak: 'break-word',
        },
      })}
      color={color}
    />
  )
}

export default FilterDisplayChip
