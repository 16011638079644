import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useStatisticsResultByGroup } from '@app/src/hooks/useStatisticsResultByGroup'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import DataHubCard from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubCard'
import DataHubChartTypeSelector from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubChartTypeSelector'
import { AnswersGroupingType } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { useEffect, useState } from 'react'
import { dataHubAllowedFilters } from '..'
import { StatisticsForQuestionPerGroup } from '../../../DataHubScene'
import DataHubSummaryChart from '../Charts/DataHubSummaryChart'
import { SummaryChartDataTypes } from '../Charts/PeriodChartsConfigs/usePeriodSummaryChartConfigs'
import { GraphSkeleton } from '../DataHubInsights'
import { useDataInsightsModal } from '../DataInsightsModalContext'

interface DataHubSummaryCardProps {
  statistics: StatisticsForQuestionPerGroup
  index: number
  periodNames: string[]
}

const DataHubSummaryCard: React.FC<DataHubSummaryCardProps> = ({ statistics, index, periodNames }) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { groupBy } = useGroupBySelector()
  const isGroupBySpend = groupBy === AnswersGroupingType.SpendClassification
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    isGroupBySpend ? ChartType.Horizontal : ChartType.Vertical,
  )
  const { openDataInsightsModal, setDataInsightsRefForIndex } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { periodStatisticSummaryResult, spendStatisticSummaryResult, isLoading } = useStatisticsResultByGroup(
    statistics,
    groupBy,
    userFilters,
  )

  useEffect(() => {
    setDataInsightsRefForIndex(eChartsRef, index)
  }, [index, setDataInsightsRefForIndex])

  if (isLoading) {
    return <GraphSkeleton />
  }

  const getStatisticsSummaryResult = () => {
    switch (groupBy) {
      case AnswersGroupingType.SpendClassification:
        return spendStatisticSummaryResult

      case AnswersGroupingType.PeriodName:
      default:
        return periodStatisticSummaryResult
    }
  }

  return (
    <DataHubCard
      statistics={statistics}
      periodNames={periodNames}
      eChartsRef={eChartsRef}
      onClickTitle={() =>
        openDataInsightsModal(statistics, {
          summaryType: [SummaryChartDataTypes.CompaniesAnswered, SummaryChartDataTypes.CompaniesAnsweredDontHaveThis],
        })
      }
      chartSelector={
        <DataHubChartTypeSelector
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
          statistics={statistics}
          isSummaryView
        />
      }
    >
      <DataHubSummaryChart
        statistics={getStatisticsSummaryResult()}
        echartsRef={eChartsRef}
        selectedChartType={selectedChartType}
        groupBy={groupBy}
        periodNames={periodNames}
      />
    </DataHubCard>
  )
}

export default DataHubSummaryCard
