import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box, Button, Chip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import OptionWithLabel from './OptionWithLabel'
import SpendFilter from './SpendFilter'

const MappingNodeFilters: React.FC<FiltersProps> = ({ allowedFilters, resetPage, implicitFilters }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Box display="flex">
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Box flexGrow={1} marginRight={1}>
                <Filter name="product.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      size="small"
                      implicitFilters={implicitFilters}
                      multiple
                      facetsParam={{
                        key: [FetchKey.ProductFacets, filterName],
                        endpoint: endpoints.mappingNodeWithFacets,
                        facetsParam: [{ name: 'product.name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.product' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>

              <Box>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.mappingNode' })}>
                              <Filter name="product.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    implicitFilters={implicitFilters}
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'product.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.product' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="product.gtin" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    implicitFilters={implicitFilters}
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'product.gtin', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.gtin' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="product.productNumber" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    implicitFilters={implicitFilters}
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'product.productNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.productNumber' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="product.productCustomId" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    implicitFilters={implicitFilters}
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'product.productCustomId', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.productCustomId' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="product.purchaseOrder" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    implicitFilters={implicitFilters}
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'product.purchaseOrder', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.purchaseOrder' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="tier" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.MappingNodeFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'tier', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="provider.organization.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.MappingNodeFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'provider.organization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.from' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="actorTypeModel.name">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'actorTypeModel.name', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              label={formatMessage({
                                                id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.activityFrom' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="parentNode.provider.organization.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.MappingNodeFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'parentNode.provider.organization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.to' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="parentNode.actorTypeModel.name">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.mappingNodeWithFacets,
                                      facetsParam: [{ name: 'parentNode.actorTypeModel.name', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              label={formatMessage({
                                                id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingNode.activityTo' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <SpendFilter spendFilterNamePrefix="provider" defaultCollapsed />
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Box>
            </>
          )
        }}
      </Filters>
    </Box>
  )
}

export default MappingNodeFilters
